import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  // Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import CardActionArea from '@material-ui/core/CardActionArea';


// TODO: make styles global to the project
const useStyles = makeStyles((
  theme // prettier-ignore
) => ({
  root: {
    height: "100%",
    borderRadius: 25,
  },
  avatar: {
    // backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

const StatCard = ({ className, name, number, link, icon, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardActionArea
      onClick={() => {
        if (link){
          navigate(link);
        }
      }}
      >
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {name}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {number}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>{icon}</Avatar>
          </Grid>
        </Grid>
      </CardContent>
      </CardActionArea>
    </Card>
  );
};

StatCard.propTypes = {
  className: PropTypes.string,
};

export default StatCard;
