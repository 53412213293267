import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// import { useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { GradeRounded } from "@material-ui/icons";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    borderRadius: 25,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
}));

const MyScoresList = ({ className, ...rest }) => {
  // const navigate = useNavigate();
  const classes = useStyles();
  const [answers, setAnswers] = useState([]);
  const limit = 5;

  useEffect(() => {
    actions
      .getMyScores()
      .then((response) => {
        setAnswers(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  // TODO: navigate to quiz onClick
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Divider />
      <List component="nav">
        {answers.slice(0, limit).map((answer, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <GradeRounded />
            </ListItemIcon>
            <ListItemText
              primary={answer.score ? parseFloat(answer.score).toFixed(3) : 0.0}
              secondary={`${answer.course_name}: ${answer.quiz_name}`}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Card>
  );
};

MyScoresList.propTypes = {
  className: PropTypes.string,
};

export default MyScoresList;
