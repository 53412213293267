import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import AccountView from "src/views/account/AccountView";
import DashboardView from "src/views/reports/DashboardView";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import CoursesListView from "src/views/courses/CoursesListView";
import CourseDetailView from "src/views/courses/CourseDetailView";
import CreateCourseView from "src/views/courses/CreateCourseView";
import MyCoursesDetailView from "src/views/courses/MyCoursesDetailView";
import EditCourseView from "src/views/courses/EditCourseView";
import RegisterView from "src/views/auth/RegisterView";
import SettingsView from "src/views/settings/SettingsView";
import Playground from "src/views/playground";
import MyEnrollmentsListView from "./views/courses/MyEnrollmentsListView";
import MyScoresListView from "./views/courses/MyScoresListView";
import AuthResetView from "./views/auth/AuthResetView";
import EmailVerifyView from "./views/auth/EmailVerifyView";
import ForgotPasswordView from "./views/auth/ForgotPasswordView";
import NotificationsListView from "./views/notifications";
import AdminDashboard from "./views/admin";
import PublicProfile from "./views/profile";
import UsersListView from "./views/users/UsersListView";
import UserDetailsView from "./views/users/UserDetailsView";

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "account", element: <AccountView /> },
      { path: "dashboard", element: <DashboardView /> },
      { path: "courses", element: <CoursesListView /> },
      { path: "create_course", element: <CreateCourseView /> },
      { path: "my_courses", element: <MyCoursesDetailView /> },
      { path: "my_enrollments", element: <MyEnrollmentsListView /> },
      { path: "my_scores", element: <MyScoresListView /> },
      {
        path: "edit_course/:slug",
        element: <EditCourseView />,
      },
      {
        path: "courses/:slug",
        element: <CourseDetailView />,
      },
      {
        path: "courses/:slug/:initPage",
        element: <CourseDetailView />,
      },
      {
        path: "user_profile/:userId",
        element: <PublicProfile />,
      },
      { path: "settings", element: <SettingsView /> },
      { path: "notifications", element: <NotificationsListView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "admin",
    element: <MainLayout />,
    children: [
      { path: "playground", element: <Playground /> },
      { path: "users_list", element: <UsersListView/> },
      { path: "user_details/:userId", element: <UserDetailsView/> },
      { path: "dashboard", element: <AdminDashboard /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "register", element: <RegisterView /> },
      { path: "password-reset/:userId/:token", element: <AuthResetView /> },
      { path: "verify-email/:userId/:token", element: <EmailVerifyView /> },
      { path: "forgot_password", element: <ForgotPasswordView /> },

      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/app/courses" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
