import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core";
import * as actions from "src/actions/auth";
import CollapseAlert from "src/components/Alert";

const useStyles = makeStyles(() => ({
  root: { borderRadius: 25 },
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [values, setValues] = useState(undefined);
  const [userId, setUserId] = useState(undefined);

  useEffect(() => {
    actions.getMyProfileDetails().then((response) => {
      setValues({
        firstName: response.data?.first_name,
        lastName: response.data?.last_name,
        email: response.data?.user,
        country: response.data?.country,
        status: response.data?.status,
        about: response.data?.about,
        verified: response.data?.verified,
      });
      setUserId(response.data?.user_id);
    });
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (values.country) {
      localStorage.setItem("country", values.country);
    } else {
      localStorage.setItem("country", "");
    }
    if (values.status) {
      localStorage.setItem("status", values.status);
    } else {
      localStorage.setItem("status", "");
    }
    if (values.about) {
      localStorage.setItem("about", values.about);
    } else {
      localStorage.setItem("about", "");
    }
    actions
      .updateUserDetails()
      .then((response) => {
        setError(true);
        setSeverity("info");
        setAlertMessage("Updated!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (values === undefined) {
    return <Typography>Loading</Typography>;
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
      // onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader subheader="Update your information" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <CollapseAlert
                message={alertMessage}
                severity={severity}
                active={error}
                onClose={() => {
                  setError(false);
                }}
              />
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} align="center">
              <Button
                variant="contained"
                color="secondary"
                disabled={values.verified}
                onClick={() => {
                  // send verification email
                  actions
                    .createEmailVerification()
                    .then((response) => {
                      setError(true);
                      setSeverity("info");
                      setAlertMessage(
                        "A verification email has been sent. Check your email to complete the process."
                      );
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {values.verified ? "Verified" : "Un-verified"}
              </Button>
              {values.verified ? (
                ""
              ) : (
                <Typography variant="body2">Click button to verify</Typography>
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="About"
                name="about"
                helperText="Write a short bio of yourself, this will be used in your courses description and public profile."
                onChange={handleChange}
                required
                value={values.about}
                variant="outlined"
                multiline
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Status"
                name="status"
                onChange={handleChange}
                required
                value={values.status}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Box md={12} xs={12} marginTop={2}>
            <Typography color="secondary" variant="body1">
              <Link
                component={RouterLink}
                to={`/app/user_profile/${userId}/`}
                variant="h6"
              >
                See my public profile
              </Link>
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
