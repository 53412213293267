import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";

import RichTextEditor from "react-rte";

import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import ImageDialog from "src/components/ImageDialog";
import ReactPlayer from "react-player";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ActionItem from "./ActionItem";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
    marginLeft: "30px",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  textEditor: {
    fontFamily: "Roboto, Helvetica Neue",
  },
}));

const ContentForm = ({
  course,
  content,
  action,
  order,
  setUpdated,
  setNewContent,
  reorderContent,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");
  const [fileUpload, setFile] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [rteValue, setRteValueState] = React.useState(() =>
    content
      ? RichTextEditor.createValueFromString(
          content.description.toString(),
          "html"
        )
      : RichTextEditor.createEmptyValue()
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [docWidth, setDocWidth] = useState(undefined);

  const handleResize = () => {
    setDocWidth(window.innerWidth * 0.7);
  };

  useEffect(() => {
    handleResize();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_ALIGNMENT_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Strikethrough", style: "STRIKETHROUGH" },
      { label: "Monospace", style: "CODE" },
    ],
    // BLOCK_ALIGNMENT_BUTTONS: [
    //   { label: "Align Left", style: "ALIGN_LEFT" },
    //   { label: "Align Center", style: "ALIGN_CENTER" },
    //   { label: "Align Right", style: "ALIGN_RIGHT" },
    //   { label: "Align Justify", style: "ALIGN_JUSTIFY" },
    // ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
      { label: "Code Block", style: "code-block" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
      { label: "Blockquote", style: "blockquote" },
    ],
  };

  const getNextOrder = () => {
    if (action === "update") {
      return content && content.serial_order;
    }
    if (action === "add") {
      return order + 1;
    }
    return content && content.serial_order;
  };

  const handleButtons = (values, resetForm) => {
    const handleAdd = () => {
      const formData = new FormData();
      formData.append("serial_order", values.serial_order);
      formData.append("course", course);
      formData.append("name", values.name);
      // formData.append("description", values.description);
      formData.append("description", rteValue.toString("html"));
      if (values.video_url) {
        formData.append("video_url", values.video_url);
      }
      if (fileUpload) {
        formData.append("document", fileUpload, fileUpload.name);
      }

      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };

      API_URL({
        method: "post",
        url: `courses/contents/`,
        data: formData,
        headers: headers,
        timeout: 3600000,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

          if (percent < 100) {
            setPercentage(percent);
            setShowProgress(true);
          }
        },
      })
        .then((response) => {
          setErrorMessage("Content Added!");
          setErrorSeverity("info");
          setError(true);
          resetForm();
          setNewContent(false);
          setUpdated((update) => {
            return update + 1;
          });
          // progress bar here
          setTimeout(() => {
            setPercentage(0);
            setShowProgress(false);
          }, 1000);
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleDelete = () => {
      // due to serial orders
      // get all current content serially without the one to delete,
      // patch with new serial orders
      reorderContent(content.serial_order);
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "delete",
        url: `courses/contents/${content.id}/`,
        data: JSON.stringify({}),
        headers: headers,
      })
        .then((response) => {
          resetForm();
          setErrorMessage("Content Removed!");
          setErrorSeverity("warning");
          setError(true);
          setNewContent(false);
          // TODO: Fix Typerror with setUpdated
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleUpdate = () => {
      const formData = new FormData();
      formData.append("serial_order", values.serial_order);
      formData.append("course", course);
      formData.append("name", values.name);
      // formData.append("description", values.description);
      formData.append("description", rteValue.toString("html"));
      if (values.video_url) {
        formData.append("video_url", values.video_url);
      }
      if (fileUpload) {
        formData.append("document", fileUpload, fileUpload.name);
      }
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "patch",
        url: `courses/contents/${content.id}/`,
        data: formData,
        headers: headers,
        timeout: 1800000,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setPercentage(percent);
            setShowProgress(true);
          }
        },
      })
        .then((response) => {
          resetForm();
          setErrorMessage("Content Updated.");
          setErrorSeverity("info");
          setError(true);
          setUpdated((update) => {
            return update + 1;
          });

          setTimeout(() => {
            setPercentage(0);
            setShowProgress(false);
          }, 1000);
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };
    if (action === "update") {
      return (
        <Grid>
          <Button
            color="secondary"
            disabled={false}
            size="small"
            type="reset"
            variant="contained"
            onClick={handleDelete}
          >
            Remove
          </Button>
          <Button
            color="primary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Grid>
      );
    }
    return (
      <Button
        color="secondary"
        disabled={false}
        size="small"
        type="reset"
        variant="contained"
        onClick={handleAdd}
      >
        Add
      </Button>
    );
  };

  const nextPage = () => {
    if (pageNumber > numPages - 1) {
      return;
    }
    setPageNumber(pageNumber + 1);
  };
  const previousPage = () => {
    if (pageNumber < 2) {
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const handleDocument = (document) => {
    if (!document) {
      return;
    }
    var extension = document.split(".").pop();
    if (extension === "png" || extension === "jpg") {
      return <CardMedia component="img" image={document} />;
    }
    if (extension === "mp4" || extension === "mp3") {
      return (
        <ReactPlayer
          url={document}
          className="react-player"
          width="100%"
          height="100%"
          controls={true}
        />
      );
    }
    if (extension === "pdf") {
      return (
        <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={docWidth} />
          <Grid align="center">
            <ActionItem
              size="small"
              icon={NavigateBeforeIcon}
              onClick={previousPage}
            />
            <ActionItem
              size="small"
              icon={NavigateNextIcon}
              onClick={nextPage}
            />
          </Grid>
        </Document>
      );
    }
  };

  window.addEventListener("resize", handleResize);

  return (
    <Formik
      initialValues={{
        serial_order: getNextOrder(),
        name: content ? content.name : "",
        description: content ? content.description : "",
        video_url: content ? content.video_url : "",
        media: content ? content.document : "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(140)
          .required("A content must have a name"),
        description: Yup.string()
          .max(5000)
          .required("A content needs a description"),
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        touched,
        values,
      }) => (
        <form onSubmit={() => resetForm()}>
          <TextField
            error={Boolean(touched.serial_order && errors.serial_order)}
            fullWidth
            helperText={touched.serial_order && errors.serial_order}
            label=""
            margin="normal"
            name="serial_order"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.serial_order}
            // disabled
            // InputProps={{
            //   readOnly: true,
            // }}
          />
          <TextField
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label="Content Title"
            margin="normal"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            variant="outlined"
          />
          {/* <TextField
            error={Boolean(touched.description && errors.description)}
            fullWidth
            helperText={touched.description && errors.description}
            id="filled-multiline-static"
            label="Content Body"
            margin="normal"
            name="description"
            multiline
            rowsMax={10}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            variant="outlined"
          /> */}
          {!values.video_url && (
            <Grid>
              {values.media ? (
                handleDocument(values.media)
              ) : (
                <Grid>
                  <Typography variant="caption" color="textSecondary">
                    Upload a media: Video (mp4), Image (png/jpg), Audio (mp3) or
                    Document (pdf).
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {` Please wait till you see a success prompt before closing the
                  page. Note that the maximum single upload is 100MB`}
                  </Typography>
                </Grid>
              )}
              <Box margin={1}>
                <input
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file.size > 2.56e8) {
                      setErrorMessage(
                        "File too large. Should not be more than 100MB! Video will not be uploaded"
                      );
                      setErrorSeverity("error");
                      setError(true);
                    } else {
                      setFile(file);
                      values.media = file.name;
                    }
                  }}
                />
              </Box>
              {showProgress && (
                <Box className={classes.root} margin={2}>
                  <LinearProgressWithLabel value={percentage} />
                </Box>
              )}
            </Grid>
          )}
          {!values.media && (
            <Grid>
              <TextField
                error={Boolean(touched.video_url && errors.video_url)}
                fullWidth
                helperText={touched.video_url && errors.video_url}
                label="Youtube Video URL"
                margin="normal"
                name="video_url"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.video_url}
                variant="outlined"
                type="url"
              />
              {values.video_url && (
                <ReactPlayer
                  url={values.video_url}
                  className="react-player"
                  width="100%"
                  // height="100%"
                  controls={true}
                />
              )}
            </Grid>
          )}
          <Grid margin={2}>
            <RichTextEditor
              className={clsx(classes.textEditor)}
              toolbarConfig={toolbarConfig}
              value={rteValue}
              onChange={setRteValueState}
              initialValues={
                content
                  ? RichTextEditor.createValueFromString(
                      content.description.toString(),
                      "html"
                    )
                  : RichTextEditor.createEmptyValue()
              }
            />
            <Typography variant="caption" color="textSecondary">
              {`Insert an inline image into the contents:`}
            </Typography>
            <ImageDialog setHtmlString={setRteValueState} />
          </Grid>

          <CollapseAlert
            active={error}
            severity={errorSeverity}
            message={errorMessage}
            onClose={() => {
              setError(false);
            }}
          />
          <Box my={3}>{handleButtons(values, resetForm)}</Box>
        </form>
      )}
    </Formik>
  );
};

export default ContentForm;
