import React from "react";
import { Grid, Typography } from "@material-ui/core";

const NoAccessGrid = () => {
  return (
    <Grid container alignContent="center" direction="column">
      <Grid item>
        <Typography variant="h5" color="textSecondary">
          You do not have access to the content.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5" color="textSecondary">
          Make sure to sign up or enroll to the course.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoAccessGrid;
