import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {
  Box,
  makeStyles,
  Button,
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  courseCard: {
    height: "100%",
  },
}));

const CourseUpdateForm = ({ className, courseId, ...rest }) => {
  const classes = useStyles();
  const [fileUpload, setFile] = useState(0);
  const [courseData, setCourseData] = useState(undefined);
  const [courseBanner, setCourseBanner] = useState("");
  const [courseCategory, setCourseCategory] = useState("none");

  // alert:
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");

  useEffect(() => {
    // load information about the current course
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "get",
      url: `courses/courses/${courseId}/`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        setCourseData(response.data);
        setCourseBanner(response.data.image);
        setCourseCategory(response.data.category);
      })
      .catch((error) => {
        console.log("Error occured: ", error);
      });
  }, [courseId]);

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const submitHandler = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("category", courseCategory);
    formData.append("requirements", values.requirements);
    formData.append("who_its_for", values.whoItsFor);
    formData.append("course_outcome", values.outcomes);
    formData.append("price", values.price);
    formData.append("published", values.published === false ? "0" : "1");
    // TODO: show alert if no file is selected
    if (fileUpload !== 0) {
      formData.append("image", fileUpload, fileUpload.name);
    }
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "patch",
      url: `courses/courses/${courseId}/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        setCourseBanner(response.data.image);
        setErrorMessage("Course info Updated!");
        setErrorSeverity("info");
        setError(true);
      })
      .catch((error) => {
        console.log("Error occured: ", error);
      });
  };

  // TODO: splash screen
  if (courseData === undefined) {
    return (
      <Typography variant="h5" color="textSecondary">
        Loading...
      </Typography>
    );
  }

  return (
    <Grid>
      <Grid>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardMedia component="img" image={courseBanner} />
          <CardActions>
            <Grid
              container
              alignContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid item xs>
                <Typography color="textSecondary" variant="h5">
                  Change the course art.
                </Typography>
                <Grid>
                  <Typography color="textSecondary" variant="caption">
                    Images of 1 by 1 aspect ratio work best.
                  </Typography>
                </Grid>
                <Grid>
                  <Typography color="textSecondary" variant="caption">
                    For example 320x320 pixels.
                  </Typography>
                </Grid>
                <input type="file" onChange={fileChangedHandler} />
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
      <Grid>
        <Formik
          initialValues={{
            name: courseData.name,
            description: courseData.description,
            requirements: courseData.requirements,
            whoItsFor: courseData.who_its_for,
            outcomes: courseData.course_outcome,
            price: courseData.price,
            published: courseData.published === "1" ? true : false,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(255)
              .required("A course must have a name"),
            description: Yup.string()
              .max(255)
              .required("A course needs a description"),
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Course Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                label="Course Description"
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
                multiline
              />
              <TextField
                error={Boolean(touched.requirements && errors.requirements)}
                fullWidth
                helperText={touched.requirements && errors.requirements}
                label="Optional: Course Prerequisites"
                margin="normal"
                name="requirements"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.requirements}
                variant="outlined"
                multiline
              />
              <TextField
                error={Boolean(touched.whoItsFor && errors.whoItsFor)}
                fullWidth
                helperText={touched.whoItsFor && errors.whoItsFor}
                label="Optional: Who is this course for?"
                margin="normal"
                name="whoItsFor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.whoItsFor}
                variant="outlined"
                multiline
              />
              <TextField
                error={Boolean(touched.outcomes && errors.outcomes)}
                fullWidth
                helperText={touched.outcomes && errors.outcomes}
                label="Optional: What will the user learn?"
                margin="normal"
                name="outcomes"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.outcomes}
                variant="outlined"
                multiline
              />
              <Grid>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink id="simple-select-placeholder-label-label">
                    Choose the course category
                  </InputLabel>
                  <Select
                    labelId="simple-select-placeholder-label-label"
                    id="simple-select-placeholder-label"
                    value={courseCategory}
                    onChange={(event) => {
                      setCourseCategory(event.target.value);
                    }}
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    <MenuItem value={"none"}>None</MenuItem>
                    <MenuItem value={"computer-science"}>
                      Computer Science
                    </MenuItem>
                    <MenuItem value={"business"}>Business</MenuItem>
                    <MenuItem value={"personal-development"}>
                      Personal Development
                    </MenuItem>
                    <MenuItem value={"language-learning"}>
                      Language Learning
                    </MenuItem>
                    <MenuItem value={"information-technology"}>
                      Information Technology
                    </MenuItem>
                    <MenuItem value={"health"}>Health</MenuItem>
                    <MenuItem value={"math-and-logic"}>Math and Logic</MenuItem>
                    <MenuItem value={"engineering"}>Engineering</MenuItem>
                    <MenuItem value={"social-sciences"}>
                      Social Sciences
                    </MenuItem>
                    <MenuItem value={"arts-and-humanities"}>
                      Arts and Humanities
                    </MenuItem>
                    <MenuItem value={"design"}>Design</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <TextField
                error={Boolean(touched.price && errors.price)}
                fullWidth
                helperText={touched.price && errors.price}
                label="Price"
                margin="normal"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                variant="outlined"
              />
              <Box alignItems="center" display="flex" ml={-1}>
                <Checkbox
                  checked={values.published}
                  name="published"
                  onChange={handleChange}
                />
                <Typography color="textSecondary" variant="body1">
                  Publish
                </Typography>
              </Box>
              {Boolean(touched.published && errors.published) && (
                <FormHelperText error>{errors.published}</FormHelperText>
              )}
              <Box my={3}>
                <CollapseAlert
                  active={error}
                  severity={errorSeverity}
                  message={errorMessage}
                  onClose={() => {
                    setError(false);
                  }}
                />
                <Button
                  color="primary"
                  disabled={false} // TODO: make programatic
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default CourseUpdateForm;
