import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Container,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Hidden,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import * as actions from "src/actions/auth";
import Interactions from "./Interactions";
import Toolbar from "./Toolbar";
import ContentGrid from "./ContentGrid";
import QuizGrid from "./QuizGrid";
import ReviewGrid from "./ReviewGrid";
import OutlineList from "./OutlineList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
    listStyle: {
      li: { marginLeft: 50, paddingLeft: 50 },
      ul: { marginLeft: 50, paddingLeft: 50 },
    },
    minHeight: "300",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ContentCard = ({ className, slug, initPage, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(initPage ? parseInt(initPage) : 1);
  const [maxProgress, setMaxProgress] = useState(
    initPage ? parseInt(initPage) : 1
  );
  const [courseContent, setCourseContent] = useState(undefined);
  const [allContent, setAllContent] = useState(undefined);
  const [enrolled, setEnrolled] = useState(false);
  const [enrollmentID, setEnrollmentID] = useState(undefined);
  const [courseId, setCourseId] = useState(undefined);
  const [coursePrice, setCoursePrice] = useState(undefined);

  const createOrder = (contents, quizzes, reviews) => {
    const totalLen = contents.length + quizzes.length;
    var allContent = new Array(totalLen);
    contents.forEach((content) => {
      allContent[content.serial_order - 1] = { type: "content", data: content };
    });
    quizzes.forEach((content) => {
      allContent[content.serial_order - 1] = { type: "quiz", data: content };
    });

    allContent.push({ type: "review", data: reviews });
    setLimit(allContent.length);
    setAllContent(allContent);
  };

  useEffect(() => {
    actions
      .getCourseDetail(slug)
      .then((response) => {
        setCourseContent(response.data);
        setCourseId(response.data.id);
        setCoursePrice(response.data.price);
        createOrder(
          response.data.contents,
          response.data.quizzes,
          response.data.reviews
        );
      })
      .catch((error) => {
        console.log("Read error: ", error);
      });
    if (courseId) {
      actions
        .getMyCourseEnrollment(courseId)
        .then((response) => {
          setEnrolled(true);
          setEnrollmentID(response.data.id);
          setMaxProgress(response.data.max_progress);
          setPage((page) =>
            response.data.progress &&
            response.data.progress <= response.data.progress_final
              ? response.data.progress
              : page
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [courseId, slug]);

  const updateProgress = () => {
    var max = Math.max(page, maxProgress);
    if (enrollmentID) {
      actions
        .patchEnrollment(page, max, allContent.length, enrollmentID)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    updateProgress(); // eslint-disable-next-line
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
    var max = Math.max(page, maxProgress);
    if (enrollmentID) {
      actions
        .patchEnrollment(value, max, allContent.length, enrollmentID)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  if (courseContent === undefined) {
    // TODO: develop loading component, or splash screen
    return (
      <Typography variant="h5" color="textSecondary">
        Loading...
      </Typography>
    );
  }

  const showToolbar = () => {
    if (!localStorage.getItem("user")) {
      return (
        <Toolbar
          slug={slug}
          price={coursePrice}
          courseId={courseId}
          ownership="guest"
          contents={allContent}
          page={page}
          setPage={setPage}
        />
      );
    } else if (localStorage.getItem("user") === courseContent.creator) {
      return (
        <Toolbar
          slug={slug}
          price={coursePrice}
          courseId={courseId}
          ownership="owner"
          contents={allContent}
          page={page}
          setPage={setPage}
        />
      );
    } else if (!enrolled) {
      return (
        <Toolbar
          slug={slug}
          price={coursePrice}
          courseId={courseId}
          ownership="non-enrolled"
          setEnrolled={setEnrolled}
          contents={allContent}
          page={page}
          setPage={setPage}
        />
      );
    }
    return (
      <Toolbar
        slug={slug}
        price={coursePrice}
        courseId={courseId}
        ownership="enrolled"
        setEnrolled={setEnrolled}
        contents={allContent}
        page={page}
        setPage={setPage}
      />
    );
  };

  if (courseContent.contents.length === 0) {
    return (
      <Container>
        <Box mb={1}>{showToolbar()}</Box>
        <Card>
          <Typography align="center" variant="h5">
            No content in this course.
          </Typography>
        </Card>
      </Container>
    );
  }

  const handleContent = (className, content, page) => {
    var permitted =
      page === 1 ||
      enrolled ||
      localStorage.getItem("user") === courseContent.creator;
    if (content.type === "content") {
      return (
        <ContentGrid
          className={clsx(classes.root, className)}
          courseContent={content.data}
          permitted={permitted}
        />
      );
    }
    if (content.type === "quiz") {
      return (
        <QuizGrid
          className={clsx(classes.root, className)}
          quizContent={content.data}
          permitted={permitted}
        />
      );
    }
    if (content.type === "review") {
      return (
        <ReviewGrid
          className={clsx(classes.root, className)}
          courseContent={content.data}
          courseId={courseId}
          permitted={permitted}
        />
      );
    }
  };

  if (allContent === undefined) {
    return (
      <Typography variant="h5" color="textSecondary">
        Loading...
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box mb={1}>{showToolbar()}</Box>
      <Grid container spacing={1}>
        <Grid item lg={10} sm={10} sx={12}>
          <Box>
            <Card className={clsx(classes.root, className)} {...rest}>
              <CardContent>
                {handleContent(className, allContent[page - 1], page)}
                <Box display="flex" align="center" justifyContent="center">
                  <Pagination
                    count={limit}
                    page={page}
                    siblingCount={0}
                    boundaryCount={1}
                    onChange={handleChange}
                    shape="rounded"
                    size="small"
                  />
                </Box>
              </CardContent>
              <Divider />
            </Card>
          </Box>
          <Box mt={1}>
            {allContent[page - 1].type === "content" && (
              <Interactions
                contentId={allContent[page - 1].data.id}
                comments={allContent[page - 1].data.comments}
                page={page}
                slug={slug}
                price={coursePrice}
              />
            )}
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item lg={2} sm={2} xs={12}>
            <OutlineList contents={allContent} page={page} setPage={setPage} />
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

ContentCard.propTypes = {
  className: PropTypes.string,
  courseId: PropTypes.string,
};

export default ContentCard;
