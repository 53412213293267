import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Box, Grid, Button, Typography, makeStyles } from "@material-ui/core";
import DisplayMarkdown from "src/components/DisplayMarkdown";
import FormLabel from "@material-ui/core/FormLabel";
import OptionsGrid from "./OptionsGrid";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";
import NoAccessGrid from "src/components/NoAccessGrid";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const QuizGrid = ({ className, quizContent, permitted }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [quizScore, setQuizScore] = useState(0);

  const handleSubmit = () => {
    // check that all questions have been answered
    // calculate quiz score
    var answersData = [];
    var score = 0;
    var answered = true;
    answers.forEach((answer) => {
      if (answer.option === undefined) {
        answered = false;
        setError(true);
        return;
      }
      score = score + parseInt(answer.isCorrect ? 1 : 0);
      answersData.push({ option: answer.option, is_correct: answer.isCorrect });
    });

    if (!answered) {
      return;
    }

    setQuizScore(score);

    // post answers data
    const data = {
      quiz: quizContent.id,
      course: quizContent.course,
      score: score,
      option_choices: answersData,
    };
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "post",
      url: `courses/answers/`,
      data: JSON.stringify(data),
      headers: headers,
    })
      .then((response) => {
        actions.postBreadcrumb(
          "post",
          `courses/answers/`,
          `answered to quiz ${quizContent.id} in course ${quizContent.course}`
        );
        setDisabled(true);
      })
      .catch((error) => {
        console.log("Error occured: ", error.message);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "get",
      url: `courses/my_quiz_answers/?quiz=${quizContent.id}`,
      data: JSON.stringify([]),
      headers: headers,
    })
      .then((response) => {
        var answersArray = [];
        response.data.option_choices.forEach((choice) => {
          answersArray.push({
            option: choice.option,
            isCorrect: choice.is_correct && false,
          });
        });
        setQuizScore(response.data.score);
        setAnswers(answersArray);
        setDisabled(true);
      })
      .catch((error) => {
        var answersArray = [];
        quizContent.questions.forEach(() => {
          answersArray.push({
            option: undefined,
            isCorrect: false,
          });
        });
        setDisabled(false);
        setAnswers(answersArray);
      });
  }, [quizContent.questions.length, quizContent.id, quizContent.questions]);

  if (permitted) {
    return (
      <Grid>
        <Grid>
          <Typography
            align="left"
            color="textSecondary"
            gutterBottom
            variant="caption"
          >
            [QUIZ]
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {quizContent.name}
          </Typography>
        </Grid>

        <DisplayMarkdown
          className={clsx(classes.root, className)}
          children={quizContent.description}
        />
        <Grid>
          {quizContent.questions.map((question, index) => {
            return (
              <Box key={index} margin={3}>
                <FormLabel component="legend">
                  {question.id}. {question.label}
                </FormLabel>
                <OptionsGrid
                  options={question.options}
                  answers={answers}
                  index={index}
                />
              </Box>
            );
          })}
        </Grid>
        {disabled && (
          <Typography color="secondary">
            Your Score: {quizScore}/{quizContent.questions.length}
          </Typography>
        )}
        <CollapseAlert
          active={error}
          message="Some questions have not been answered!"
          severity="error"
          onClose={() => {
            setError(false);
          }}
        />
        {quizContent.questions.length > 1 && (
          <Grid align="right">
            <Box margin={1}>
              <Button
                color="secondary"
                disabled={disabled}
                type="submit"
                size="small"
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }
  return <NoAccessGrid />;
};

export default QuizGrid;
