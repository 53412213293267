import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Grid, Typography, makeStyles, CardMedia } from "@material-ui/core";
import ReactPlayer from "react-player";
import NoAccessGrid from "src/components/NoAccessGrid";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ActionItem from "src/views/courses/EditCourseView/ActionItem";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ContentGrid = ({ className, courseContent, permitted }) => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [docWidth, setDocWidth] = useState(undefined);

  const handleResize = () => {
    setDocWidth(
      window.innerWidth <= 760
        ? window.innerWidth * 0.9
        : window.innerWidth * 0.7
    );
  };

  useEffect(() => {
    handleResize();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const nextPage = () => {
    if (pageNumber > numPages - 1) {
      return;
    }
    setPageNumber(pageNumber + 1);
  };
  const previousPage = () => {
    if (pageNumber < 2) {
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const handleDocument = (document) => {
    if (!document) {
      return;
    }
    var extension = document.split(".").pop();
    if (extension === "png" || extension === "jpg") {
      return (
        <Grid>
          <CardMedia component="img" image={document} />
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {courseContent.name}
          </Typography>
        </Grid>
      );
    }
    if (extension === "mp4" || extension === "mp3") {
      return (
        <Grid>
          <ReactPlayer
            url={document}
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
          />
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {courseContent.name}
          </Typography>
        </Grid>
      );
    }
    if (extension === "pdf") {
      return (
        <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={docWidth} />
          <Grid align="center">
            <ActionItem
              size="small"
              icon={NavigateBeforeIcon}
              onClick={previousPage}
            />
            <ActionItem
              size="small"
              icon={NavigateNextIcon}
              onClick={nextPage}
            />
          </Grid>
        </Document>
      );
    }
  };

  const handleContent = () => {
    if (courseContent.document) {
      return handleDocument(courseContent.document);
    }

    if (courseContent.video_url) {
      return (
        <Grid>
          <ReactPlayer
            url={courseContent.video_url}
            className="react-player"
            width="100%"
            controls={true}
          />
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {courseContent.name}
          </Typography>
        </Grid>
      );
    }

    return (
      <Typography align="left" color="textPrimary" gutterBottom variant="h4">
        {courseContent.name}
      </Typography>
    );
  };

  window.addEventListener("resize", handleResize);

  if (permitted) {
    return (
      <Grid>
        {handleContent()}
        <div
          className={clsx(classes.textEditor)}
          dangerouslySetInnerHTML={{ __html: courseContent.description }}
        />
      </Grid>
    );
  }
  return <NoAccessGrid />;
};

export default ContentGrid;
