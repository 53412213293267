import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import LocalLibraryRoundedIcon from "@material-ui/icons/LocalLibraryRounded";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import SettingsApplicationsRoundedIcon from "@material-ui/icons/SettingsApplicationsRounded";
import NavItem from "./NavItem";
import * as actions from "src/actions/auth";
import AccountTreeIcon from '@material-ui/icons/AccountTree';

const items = [
  {
    href: "/app/courses",
    icon: LocalLibraryRoundedIcon,
    title: "Courses",
  },
  {
    href: "/app/dashboard",
    icon: DashboardRoundedIcon,
    title: "Dashboard",
  },
  {
    href: "/app/account",
    icon: AccountBoxRoundedIcon,
    title: "Account",
  },
  {
    href: "/app/settings",
    icon: SettingsApplicationsRoundedIcon,
    title: "Settings",
  },
];

const adminItems = [
  ...items,
  ...[{
    href: "/admin/dashboard",
    icon: AccountTreeIcon,
    title: "Admin Dashboard",
  }]
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [avatar, setAvatar] = useState("");
  const [fullName, setFullName] = useState("");
  const [isStaff, setIsStaff] = useState(false);

  useEffect(() => {
    actions
      .getMyProfileDetails()
      .then((response) => {
        setIsStaff(response.data.is_staff)
        localStorage.setItem("user_id", response.data.user_id);
        if (response.data.image) {
          setAvatar(response.data.image);
          localStorage.setItem("avatar", response.data.image);
        } else {
          setAvatar("/static/images/avatars/profile_image_placeholder.png");
          localStorage.setItem(
            "avatar",
            "/static/images/avatars/profile_image_placeholder.png"
          );
        }
        if (response.data.country) {
          localStorage.setItem("country", response.data.country);
        } else {
          localStorage.setItem("country", "");
        }
        actions
          .getUserDetails()
          .then((response) => {
            if (response.data) {
              setFullName(
                response.data.first_name + " " + response.data.last_name
              );
              localStorage.setItem("first_name", response.data.first_name);
              localStorage.setItem("last_name", response.data.last_name);
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      })
      .catch((error) => {
        console.log("Unable to get profile detail: ", error);
      });
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleItems = () =>{
    if (isStaff){
      return adminItems
    } else {

      return items
    }
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={avatar}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {fullName}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {handleItems().map((
            item // prettier-ignore
          ) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2} m={2} bgcolor="background.dark">
        <Typography align="center" gutterBottom variant="h4">
          Hi,
        </Typography>
        <Typography align="center" variant="body2">
          How is your experience with Beadaut?
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            color="primary"
            component="a"
            href="https://us2.list-manage.com/survey?u=88d5ac386d24ba0572266bf9b&id=6a41951c80&attribution=false"
            target="_blank"
            variant="contained"
          >
            Send a feedback
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
