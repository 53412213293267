import React from "react";
import { Collapse } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const CollapseAlert = ({ message, active, severity, onClose }) => {
  return (
    <Collapse in={active} timeout="auto">
      <Alert severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Collapse>
  );
};

export default CollapseAlert;

