import React from "react";
import Page from "src/components/Page";
import { Box, makeStyles, Container, Typography } from "@material-ui/core";
import CreateCourseForm from "./CreateCourseForm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  courseCard: {
    height: "100%",
  },
}));

const CreateCourseView = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Create Course">
      <Container maxWidth={false}>
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Create a new course
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Teach what you know.
          </Typography>
        </Box>
        <CreateCourseForm />
      </Container>
    </Page>
  );
};

export default CreateCourseView;
