import React, { useEffect } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  courseCard: {
    height: "100%",
  },
}));

const Playground = () => {
  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <Page className={classes.root} title="Playground">
      <Container maxWidth={false}>
        <Box>
          <Grid>
            <Typography variant="h1">Playground</Typography>
            <Typography>Play all you want</Typography>
          </Grid>
          <Grid></Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default Playground;
