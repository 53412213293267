import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: { borderRadius: 25 },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const BioCard = ({ className, profileData, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box display="flex" flexDirection="column">
          <Typography color="textPrimary" gutterBottom variant="h5">
            Bio
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {profileData.about}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

BioCard.propTypes = {
  className: PropTypes.string,
};

export default BioCard;
