import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImagesList from "./ImagesList";
import CollapseAlert from "src/components/Alert";
import { PhotoLibrary } from "@material-ui/icons";
import * as functions from "src/components/Functions";
import { API_URL } from "src/constants";
import { resizeFile } from "src/components/Functions";

const ImageDialog = ({ className, ...rest }) => {
  const [open, setOpen] = React.useState(false);
  const [updated, setUpdated] = useState(0);
  const [fileUpload, setFile] = useState(0);
  const [fileAbout, setAbout] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");
  const [percentage, setPercentage] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    setFile(0);
  }, [updated]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = () => {
    if (fileUpload) {
      var url = `images/`;
      var action = "post";
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      };

      const formData = new FormData();
      formData.append("about", fileAbout);
      formData.append("link", fileUpload, fileUpload.name);
      API_URL({
        method: action,
        url: url,
        data: formData,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setPercentage(percent);
            setShowProgress(true);
          }
        },
      })
        .then((response) => {
          // progress bar here
          setTimeout(() => {
            setPercentage(0);
            setShowProgress(false);
          }, 1000);
        })
        .catch((error) => {});
    }
    setUpdated((update) => {
      return update + 1;
    });
  };

  return (
    <Grid className={className} container>
      <Grid>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          <PhotoLibrary />
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Images</DialogTitle>
          <CollapseAlert
            active={error}
            severity={errorSeverity}
            message={errorMessage}
            onClose={() => {
              setError(false);
            }}
          />
          <DialogContent>
            <Typography variant="caption" color="textSecondary">
              Tap on image to insert.
            </Typography>
            <DialogContentText>
              <ImagesList
                setOpen={setOpen}
                setUpdated={setUpdated}
                updated={updated}
                {...rest}
              />
            </DialogContentText>
            <Grid>
              <input
                type="file"
                onChange={async (event) => {
                  try {
                    const file = event.target.files[0];
                    const image = await resizeFile(file);
                    if (image.size > 1024000) {
                      setErrorMessage(
                        "File too large. Should not be more than 1MB! Image will not be uploaded"
                      );
                      setErrorSeverity("error");
                      setError(true);
                    } else {
                      setFile(image);
                      setAbout(file.name);
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />
              <Button
                onClick={handleUpload}
                size="small"
                color="secondary"
                variant="outlined"
              >
                upload
              </Button>
              {showProgress && (
                <Box className={className} margin={2}>
                  <functions.LinearProgressWithLabel value={percentage} />
                </Box>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default ImageDialog;
