import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, TextField } from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";
import * as actions from "src/actions/auth";
import { onKeyDown } from "src/components/Functions";

const WriteCommentForm = ({
  className,
  content,
  setUpdated,
  postNotification,
}) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");

  const handleButton = (values, resetForm) => {
    const handlePost = () => {
      const formData = new FormData();
      formData.append("body", values.body);
      formData.append("content", content);

      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "post",
        url: `courses/comments/`,
        data: formData,
        headers: headers,
      })
        .then((response) => {
          actions.postBreadcrumb(
            "post",
            `courses/comments/`,
            `commented in content ${content}`
          );
          postNotification(values.body);
          resetForm();
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error.message);
          setErrorMessage(error.message);
          setErrorSeverity("error");
        });
    };

    return (
      <Button
        color="secondary"
        disabled={false}
        size="small"
        variant="contained"
        onClick={handlePost}
      >
        Post
      </Button>
    );
  };
  return (
    <Formik
      initialValues={{
        body: "",
      }}
      validationSchema={Yup.object().shape({
        body: Yup.string()
          .max(1000)
          .required("Comment is empty"),
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleReset,
        touched,
        resetForm,
        values,
      }) => (
        <form onKeyDown={onKeyDown}>
          <CollapseAlert // in case comment is not submitted
            active={error}
            severity={errorSeverity}
            message={errorMessage}
            onClose={() => {
              setError(false);
            }}
          />
          <TextField
            id="standard-basic"
            error={Boolean(touched.body && errors.body)}
            fullWidth
            helperText={touched.body && errors.body}
            label="Write a comment..."
            margin="normal"
            name="body"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.body}
            variant="outlined"
          />
          <Box my={3}>{handleButton(values, resetForm)}</Box>
        </form>
      )}
    </Formik>
  );
};

export default WriteCommentForm;
