import React, { useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
    marginLeft: "30px",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const OptionForm = ({
  className,
  question,
  option,
  action,
  setUpdated,
  order,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");
  const [checked, setChecked] = React.useState(option && option.is_correct);

  const getNextOrder = () => {
    if (action === "update") {
      return option && option.option_order;
    }
    if (action === "add") {
      return order + 1;
    }
    return option && option.option_order;
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleButtons = (values, resetForm) => {
    const handleAdd = () => {
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "post",
        url: `courses/options/`,
        data: JSON.stringify({
          text: values.text,
          is_correct: checked,
          question: question,
          option_order: values.option_order,
        }),
        headers: headers,
      })
        .then((response) => {
          resetForm();
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleDelete = () => {
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "delete",
        url: `courses/options/${option.id}/`,
        data: JSON.stringify({}),
        headers: headers,
      })
        .then((response) => {
          setErrorMessage("Content Removed!");
          setErrorSeverity("info");
          setError(true);
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleUpdate = () => {
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "patch",
        url: `courses/options/${option.id}/`,
        data: JSON.stringify({
          text: values.text,
          is_correct: checked,
          question: values.question,
          option_order: values.option_order,
        }),
        headers: headers,
      })
        .then((response) => {
          setErrorMessage("Option Updated!");
          setErrorSeverity("info");
          setError(true);
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    if (action === "update") {
      return (
        <Grid>
          <Button
            color="secondary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleDelete}
          >
            Remove
          </Button>
          <Button
            color="primary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Grid>
      );
    }
    return (
      <Button
        color="secondary"
        disabled={false}
        size="small"
        type="submit"
        variant="contained"
        onClick={handleAdd}
      >
        Add
      </Button>
    );
  };

  return (
    <Grid>
      <Formik
        className={clsx(classes.root, className)}
        initialValues={{
          option_order: getNextOrder(),
          text: option ? option.text : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required("An option must have a name"),
          description: Yup.string()
            .max(1000)
            .required("An option needs a description"),
        })}
        onSubmit={() => {}}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.option_order && errors.option_order)}
              fullWidth
              helperText={touched.option_order && errors.option_order}
              label=""
              margin="normal"
              name="option_order"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.option_order}
              disabled
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              error={Boolean(touched.text && errors.text)}
              fullWidth
              helperText={touched.text && errors.text}
              label="Option:"
              margin="normal"
              name="text"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.text}
              variant="outlined"
              size="small"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckChange}
                  title="Is Answer"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Is Answer"
            />
            <CollapseAlert
              active={error}
              severity={errorSeverity}
              message={errorMessage}
              onClose={() => {
                setError(false);
              }}
            />
            <Box my={3}>{handleButtons(values, resetForm)}</Box>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default OptionForm;
