import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { GradeRounded } from "@material-ui/icons";
import * as actions from "src/actions/auth";

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: 25,
  },
  image: {
    height: 48,
    width: 48,
  },
});

const MyScores = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [answers, setAnswers] = useState([]);
  const limit = 5;

  useEffect(() => {
    actions
      .getMyScores()
      .then((response) => {
        setAnswers(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  const handleViewAllClick = () => {
    navigate("/app/my_scores");
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader subtitle={`${answers.length} in total`} title="My Scores" />
      <Divider />
      {answers.length > 0 ? (
        <List component="nav">
          {answers.slice(0, limit).map((answer, i) => (
            <ListItem key={i}>
              <ListItemIcon>
                <GradeRounded />
              </ListItemIcon>
              <ListItemText
                primary={
                  answer.score ? parseFloat(answer.score).toFixed(1) : 0.0
                }
                secondary={`${answer.course_name}: ${answer.quiz_name}`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          <ListItem>
            <Typography variant="caption" color="secondary">
              You are yet take a quiz!
            </Typography>
          </ListItem>
        </List>
      )}
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={handleViewAllClick}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

MyScores.propTypes = {
  className: PropTypes.string,
};

export default MyScores;
