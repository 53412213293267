import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Avatar,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Badge,
} from "@material-ui/core";
import Page from "src/components/Page";
import * as actions from "src/actions/auth";
import { CallToActionRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    borderRadius: 25,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const NotificationsListView = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const limit = 20; // TODO: add a view more button to increase limit size

  useEffect(() => {
    actions
      .getNotifications()
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Page className={classes.root} title="Notifications">
      <Container maxWidth={false}>
        <Grid>
          <Typography variant="h2">Notifications</Typography>
        </Grid>
        <Card className={clsx(classes.root)}>
          {notifications.length > 0 ? (
            <List component="nav">
              {notifications.slice(0, limit).map((notification, i) => (
                <ListItem
                  key={i}
                  button
                  selected={notification.read}
                  onClick={() => {
                    actions
                      .readNotifications(notification.id)
                      .then((response) => {})
                      .catch((error) => {});
                    navigate(notification.link);
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      badgeContent={1}
                      variant="dot"
                      color={notification.read ? "default" : "secondary"}
                    >
                      <Avatar
                        alt="Course"
                        src={notification.course_image}
                        variant="rounded"
                      >
                        <CallToActionRounded />
                      </Avatar>
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${notification.title}`}
                    secondary={`${notification.body}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <List>
              <ListItem>
                <Typography color="secondary">
                  You have not notifications.
                </Typography>
              </ListItem>
            </List>
          )}
        </Card>
      </Container>
    </Page>
  );
};

export default NotificationsListView;
