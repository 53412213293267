import { API_URL } from "src/constants";
import * as actionTypes from "./actionTypes";
import axios from "axios";
import { addDepositFee } from "src/components/Functions";

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const authSuccess = (response) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    response,
  };
};

export const uploadImage = (fileUpload, about) => {
  var url = `images/`;
  var action = "post";
  // postBreadcrumb(action, url, `uploaded image`);
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  const formData = new FormData();
  formData.append("about", about);
  formData.append("link", fileUpload, fileUpload.name);

  return API_URL({
    method: action,
    url: url,
    data: formData,
    headers: headers,
  });
};

export const deleteImage = (id) => {
  var url = `images/${id}/`;
  var action = "delete";
  // postBreadcrumb(action, url, `uploaded image`);
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: action,
    url: url,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getImages = (remark) => {
  var url = `images/?user=${localStorage.getItem("user_id")}`;
  var action = "get";
  // postBreadcrumb(action, url, `uploaded image`, remark);
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: action,
    url: url,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getCreatorId = (email) => {
  // get creator id from email
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "get",
    url: `users/all/?email=${email}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

async function getLocation() {
  var response = await axios.get("https://extreme-ip-lookup.com/json/");
  return response.data;
}

export async function postBreadcrumb(action, url, remark) {
  if (parseInt(process.env.REACT_APP_BREADCRUMBS)) {
    var location = await getLocation();
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "post",
      url: `breadcrumbs/breadcrumbs/`,
      data: JSON.stringify({
        user: localStorage.getItem("user"),
        action: action,
        url: url,
        remark: remark,
        organization: process.env.REACT_APP_ORGANIZATION,
        city: location?.city,
        country: location?.country,
      }),
      headers: headers,
    })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }
}

export const getUserIdfromCourseId = (courseId) => {
  getCourseDetail(courseId)
    .then((response) => {
      getCreatorId(response.data.creator)
        .then((response) => {
          return response.data.id;
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
  return;
};

export const createNotification = (
  title,
  body,
  link,
  courseId,
  toUser = null
) => {
  // TODO: implement case where toUser is supplied
  // TODO: create better logic
  getCourseDetail(courseId)
    .then((response) => {
      title = title + ` ${response.data.name}`;
      getCreatorId(response.data.creator)
        .then((response) => {
          let userId = response.data[0].id;
          if (!(userId === parseInt(localStorage.getItem("user_id")))) {
            const token = localStorage.getItem("token");
            const payload = {
              title: title,
              body: body,
              to: userId,
              link: link,
            };
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            };
            return API_URL({
              method: "post",
              url: "notifications/",
              data: JSON.stringify(payload),
              headers: headers,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
      console.log("get course detail error");
    });

  // not a good way to show error
  return API_URL({
    method: "get",
    url: `notifications/`,
    data: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });
};

export const getNotifications = (read) => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  var url = read ? `notifications/?read=${read}` : `notifications/`;
  return API_URL({
    method: "get",
    url: url,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const readNotifications = (id) => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "patch",
    url: `notifications/${id}/`,
    data: JSON.stringify({
      read: true,
    }),
    headers: headers,
  });
};

export const createUser = (email, password, firstname, lastname) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return API_URL({
    method: "post",
    url: "users/",
    data: JSON.stringify({
      email: email,
      password: password,
      first_name: firstname,
      last_name: lastname,
    }),
    headers: headers,
  });
};

export const authLogin = (email, password) => {
  var url = "users/token/";
  var action = "post";
  const headers = {
    "content-type": "application/json",
  };
  return API_URL({
    method: action,
    url: url,
    data: JSON.stringify({
      email: email,
      password: password,
    }),
    headers: headers,
  });
};

export const changePassword = (currentPassword, password) => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "patch",
    url: "users/me/",
    data: JSON.stringify({
      current_password: currentPassword,
      password: password,
    }),
    headers: headers,
  });
};

export const resetPassword = (token, userId, password) => {
  const headers = {
    "content-type": "application/json",
  };
  return API_URL({
    method: "patch",
    url: "users/password/",
    data: JSON.stringify({
      token: token,
      user_id: userId,
      password: password,
    }),
    headers: headers,
  });
};

export const forgotPassword = (email) => {
  const headers = {
    "content-type": "application/json",
  };
  return API_URL({
    method: "patch",
    url: "users/password/",
    data: JSON.stringify({
      email: email,
    }),
    headers: headers,
  });
};

export const createProfile = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "post",
    url: "users/profiles/",
    data: JSON.stringify({
      access: process.env.REACT_APP_DEFAULT_ACCESS,
      organization: process.env.REACT_APP_ORGANIZATION,
    }),
    headers: headers,
  });
};

export const getMyProfileDetails = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };

  return API_URL({
    method: "get",
    url: "users/my_profile/",
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getUserProfileDetails = (userId) => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "get",
    url: `users/profiles/${userId}/`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getUserProfileDetailsWithProfileId = (userId) => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "get",
    url: `users/profiles/?user_id=${userId}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getStudentUsers = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "get",
    url: `users/profiles/?access=0&organization=${process.env.REACT_APP_ORGANIZATION}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getTutorUsers = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };
  return API_URL({
    method: "get",
    url: `users/profiles/?access=1&organization=${process.env.REACT_APP_ORGANIZATION}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const patchProfileVerified = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };

  getMyProfileDetails()
    .then((response) => {
      API_URL({
        method: "patch",
        url: `users/profiles/${response.data.id}/`,
        data: JSON.stringify({ verified: true }),
        headers: headers,
      })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getUserDetails = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };

  return API_URL({
    method: "get",
    url: "users/me/",
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const updateUserDetails = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };

  return API_URL({
    method: "patch",
    url: `users/profiles/${localStorage.getItem("user_id")}/`,
    data: JSON.stringify({
      country: localStorage.getItem("country"),
      status: localStorage.getItem("status"),
      about: localStorage.getItem("about"),
    }),
    headers: headers,
  });
};

export const updateUserDetailsWith = (userId, data) => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };

  return API_URL({
    method: "patch",
    url: `users/profiles/${userId}/`,
    data: JSON.stringify(data),
    headers: headers,
  });
};

export const getAllUsers = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };

  return API_URL({
    method: "get",
    url: `users/profiles/?organization=${process.env.REACT_APP_ORGANIZATION}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const updateAvatar = () => {
  const token = localStorage.getItem("token");
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${token}`,
  };

  return API_URL({
    method: "put",
    url: `users/profiles/${localStorage.getItem("user_id")}`,
    data: JSON.stringify({
      country: localStorage.getItem("country"),
    }),
    headers: headers,
  });
};

export const getCourses = () => {
  // var url = "courses/courses/?published=1";
  var url = `courses/synopsis/?organization=${process.env.REACT_APP_ORGANIZATION}&published=1`;
  var action = "get";
  postBreadcrumb(action, url, "checked published courses");
  const headers = {
    "Content-Type": "application/json",
  };

  return API_URL({
    method: "get",
    url: url,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getMyCourses = () => {
  var url = "courses/my_courses/";
  var action = "get";
  postBreadcrumb(action, url, "checked my courses");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: action,
    url: url,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getUserCourses = (userId) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return API_URL({
    method: "get",
    url: `courses/courses/?creator=${userId}&organization=${process.env.REACT_APP_ORGANIZATION}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getCourseDetail = (courseId) => {
  var url = `courses/courses/${courseId}/`;
  var action = "get";
  postBreadcrumb(action, url, `checked course detail: ${courseId}`);
  const headers = {
    "Content-Type": "application/json",
  };

  return API_URL({
    method: "get",
    url: url,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getMyCourseEnrollment = (courseId) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "get",
    url: `courses/my_enrollments/?course=${courseId}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getMyEnrollments = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "get",
    url: `courses/my_enrollments/`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getMyCourseEnrollments = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "get",
    url: `courses/my_course_enrollments/`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getCourseEnrollments = (courseId) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "get",
    url: `courses/course_enrollments/?course=${courseId}`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const getUserEnrollments = (userId) => {
  var url = `courses/enrollments/?user=${userId}`;
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "get",
    url: url,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const postEnrollment = (courseId) => {
  var url = `courses/enrollments/`;
  var action = "post";
  postBreadcrumb(action, url, `enrolled for ${courseId}`);
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "post",
    url: url,
    data: JSON.stringify({ course: courseId }),
    headers: headers,
  });
};

export const patchEnrollment = (value, max, final, enrollmentID) => {
  var url = `courses/enrollments/${enrollmentID}/`;
  var action = "patch";
  postBreadcrumb(
    action,
    url,
    `progressed to page ${value} in course ${enrollmentID}`
  );
  const headers = {
    "content-type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: action,
    url: url,
    data: JSON.stringify({
      progress: value,
      max_progress: max,
      progress_final: final,
    }),
    headers: headers,
  });
};

export const getMyScores = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "get",
    url: `courses/my_quiz_answers/`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const createEmailVerification = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "post",
    url: `emails/`,
    data: JSON.stringify({}),
    headers: headers,
  });
};

export const verifyEmail = (user_id, token) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "post",
    url: `emails/verify/`,
    data: JSON.stringify({
      token: token,
      user_id: user_id,
    }),
    headers: headers,
  });
};

export const confirmPayment = (reference, courseId) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  return API_URL({
    method: "post",
    url: `payments/confirmation/`,
    data: JSON.stringify({
      transaction_id: reference,
      course_id: courseId,
    }),
    headers: headers,
  });
};

export const makePayment = (
  courseId,
  amount,
  callbackFunction,
  onCloseFunction
) => {
  // getMyProfileDetails()
  const email = localStorage.getItem("user");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");

  let handler = window.PaystackPop.setup({
    key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    email: email,
    amount: addDepositFee(amount * 100),
    firstname: first_name,
    lastname: last_name,
    // ref: `${first_name}-${last_name}-course-${courseId}-payment`,
    // ref: `course_${courseId}_payment_${new Date().getTime().toString()}`,

    callback: callbackFunction,
    onClose: onCloseFunction,
  });
  handler.openIframe();
};

export const logout = () => {
  postBreadcrumb("logout", "/", "logged out");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("user_id");
  localStorage.removeItem("first_name");
  localStorage.removeItem("last_name");
  localStorage.removeItem("country");
  localStorage.removeItem("avatar");
  localStorage.removeItem("status");
  localStorage.removeItem("about");
};
