import React, { useState, useEffect } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Page from "src/components/Page";
import Toolbar from "./Toolbar";
import CourseCard from "./CourseCard";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  courseCard: {
    height: "100%",
  },
}));

const CourseList = () => {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 40;

  // const getEnrollmentsSize = (course) => {
  //   var size = 0;
  //   actions
  //     .getCourseEnrollments(course)
  //     .then((response) => {
  //       size = response.data.length;
  //       // console.log("response.data.length: ", size);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       size = 0;
  //     });
  //   return size;
  // };

  const rankCourses = (coursesList) => {
    // rank courses and drop some courses if no content
    coursesList = coursesList.sort((a, b) => {
      return b.contents.length - a.contents.length; // order is reversed
    });
    var newCoursesList = []; //new Array(totalLen)
    coursesList.forEach((course) => {
      // var enrollmentsSize;
      // enrollmentsSize = getEnrollmentsSize(course.id);
      if (course.contents.length > 0) {
        newCoursesList.push(course);
      }
    });
    return newCoursesList;
  };

  useEffect(() => {
    actions
      .getCourses()
      .then((response) => {
        setCourses(() => {
          return rankCourses(response.data);
        });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page className={classes.root} title="Courses">
      <Container maxWidth={false}>
        <Toolbar />
        <Grid container spacing={1}>
          {courses
            .slice((page - 1) * limit, (page - 1) * limit + limit)
            .map((course, index) => (
              <Grid item key={index} lg={3} md={4} sm={4} xs={12}>
                <CourseCard className={classes.courseCard} course={course} />
              </Grid>
            ))}
        </Grid>
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination
            color="secondary"
            count={Math.ceil(courses.length / limit)}
            // boundaryCount={limit}
            page={page}
            onChange={handlePageChange}
            size="small"
            showFirstButton
            showLastButton
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CourseList;
