import React from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  courseCard: {
    height: "100%",
  },
}));

const OutlineList = ({ className, contents, page, setPage, afterClick }) => {
  const classes = useStyles();

  return (
    <List
      className={classes.root}
      component="nav"
      aria-label="secondary mailbox folders"
      dense
    >
      {contents.slice(0, contents.length - 1).map((content, index) => (
        <ListItem
          button
          selected={index + 1 === page}
          onClick={() => {
            setPage(() => {
              return index + 1;
            });
            if (afterClick) afterClick();
          }}
          key={index}
        >
          <ListItemText
            primary={content.data.name}
            secondary={content.type === "quiz" ? "Quiz" : ""}
            color="secondary"
          />
        </ListItem>
      ))}
    </List>
  );
};

export default OutlineList;
