import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { API_URL } from "src/constants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
}));

const LineChart = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataLabels, setDataLabels] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [enrollmentsData, setEnrollmentsData] = useState([]);
  const [interval, setInterval] = useState(7);

  useEffect(() => {
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    API_URL({
      method: "get",
      url: `analytics/daily_users/?days=${interval}`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        setDataLabels(response.data.dates);
        setUsersData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    API_URL({
      method: "get",
      url: `analytics/enrollments/?days=${interval}`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        setEnrollmentsData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [interval]);

  const data = {
    labels: dataLabels,
    datasets: [
      {
        label: "User Signups",
        data: usersData,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Enrollments",
        data: enrollmentsData,
        fill: true,
        borderColor: "#742774",
      },
    ],
  };
  const handleChange = (event) => {
    setInterval(event.target.value);
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Signups and Enrollments"
        action={
          <FormControl
            // variant="filled"
            className={classes.formControl}
            size="small"
          >
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={interval}
              onChange={handleChange}
            >
              <MenuItem value={7}>7 Days</MenuItem>
              <MenuItem value={30}>30 Days</MenuItem>
              <MenuItem value={90}>90 Days</MenuItem>
              <MenuItem value={365}>1 Year</MenuItem>
            </Select>
          </FormControl>
        }
      />
      <Divider />
      <CardContent>
        <Line data={data} />
      </CardContent>
    </Card>
  );
};

export default LineChart;
