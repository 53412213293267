import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import * as actions from "src/actions/auth";
import { Collapse } from "@material-ui/core";
import CollapseAlert from "src/components/Alert";
import Welcome from "src/views/reports/DashboardView/Welcome";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/app/dashboard");
    }
  });

  // TODO: confirm in staging and remove
  console.log("Default access: ", process.env.REACT_APP_DEFAULT_ACCESS);

  return (
    <Page className={classes.root} title="Register">
      <Grid item xs={12} align="center">
        <Collapse in={error} timeout="auto">
          <CollapseAlert
            severity="error"
            message="Something went wrong!"
            onClose={() => {
              setError(false);
              setDisable(false);
            }}
          />
        </Collapse>
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Grid item sm={12} xs={12}>
            <Welcome />
          </Grid>
          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              password: "",
              policy: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              firstName: Yup.string()
                .max(255)
                .required("First name is required"),
              lastName: Yup.string()
                .max(255)
                .required("Last name is required"),
              password: Yup.string()
                .max(255)
                .required("password is required"),
              policy: Yup.boolean().oneOf([true], "This field must be checked"),
            })}
            onSubmit={(values) => {
              actions
                .createUser(
                  values.email,
                  values.password,
                  values.firstName,
                  values.lastName
                )
                .then((response) => {
                  actions
                    .authLogin(values.email, values.password)
                    .then((response) => {
                      localStorage.setItem("token", response.data.token);
                      localStorage.setItem("user", values.email);
                      actions
                        .createProfile()
                        .then((response) => {
                          actions
                            .getMyProfileDetails()
                            .then((response) => {
                              localStorage.setItem("user_id", response.data.id);
                              if (response.data.image) {
                                localStorage.setItem(
                                  "avatar",
                                  response.data.image
                                );
                              } else {
                                localStorage.setItem(
                                  "avatar",
                                  "/static/images/avatars/profile_image_placeholder.png"
                                );
                              }
                              if (response.data.country) {
                                localStorage.setItem(
                                  "country",
                                  response.data.country
                                );
                              } else {
                                localStorage.setItem("country", "");
                              }
                              actions
                                .getUserDetails()
                                .then((response) => {
                                  if (response.data.first_name) {
                                    localStorage.setItem(
                                      "first_name",
                                      response.data.first_name
                                    );
                                  }
                                  if (response.data.last_name) {
                                    localStorage.setItem(
                                      "last_name",
                                      response.data.last_name
                                    );
                                  }
                                })
                                .catch((error) => {
                                  console.log("Error: ", error);
                                });
                            })
                            .catch((error) => {
                              console.log(
                                "Unable to get profile detail: ",
                                error
                              );
                            });
                          navigate("/app/dashboard");
                        })
                        .catch((error) => {
                          console.log("Unable to create profile: ", error);
                        });
                    })
                    .catch((error) => {
                      console.log("Unable to log in user: ", error);
                      setError(true);
                      setDisable(true);
                    });
                })
                .catch((error) => {
                  console.log("Unable to create user: ", error);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box alignItems="center" display="flex" ml={-1}>
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography color="textSecondary" variant="body1">
                    I have read the{" "}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}
                <Box my={3}>
                  <Button
                    color="primary"
                    disabled={disable}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Have an account?{" "}
                  <Link component={RouterLink} to="/login" variant="h6">
                    Log in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
