import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const WriteReviewForm = ({ className, courseId, score, setValue }) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");

  const handleButton = (values, resetForm) => {
    const handlePost = () => {
      const formData = new FormData();
      formData.append("body", values.body);
      formData.append("score", score);
      formData.append("course", courseId);

      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "post",
        url: `courses/reviews/`,
        data: formData,
        headers: headers,
      })
        .then((response) => {
          resetForm();
          setValue(0);
        })
        .catch((error) => {
          setError(true);
          setErrorMessage("Not allowed!");
          setErrorSeverity("error");
        });
    };

    return (
      <Button
        color="secondary"
        disabled={false}
        size="small"
        variant="contained"
        onClick={handlePost}
      >
        Post
      </Button>
    );
  };
  return (
    <Formik
      initialValues={{
        body: "",
      }}
      validationSchema={Yup.object().shape({
        body: Yup.string()
          .max(1000)
          .required("Comment is empty"),
      })}
      onSubmit={() => {}}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        touched,
        values,
      }) => (
        <form>
          <Divider />
          <CollapseAlert // in case review is not submitted
            active={error}
            severity={errorSeverity}
            message={errorMessage}
            onClose={() => {
              setError(false);
            }}
          />
          <Grid>
            <Typography component="legend" variant="body2">
              Add a review Score:
            </Typography>
            <Rating
              name="simple-controlled"
              value={score}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </Grid>
          <TextField
            id="standard-basic"
            error={Boolean(touched.body && errors.body)}
            fullWidth
            helperText={touched.body && errors.body}
            label="Write a review..."
            margin="normal"
            name="body"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.body}
            variant="outlined"
            multiline
          />
          <Box my={3}>{handleButton(values, resetForm)}</Box>
        </form>
      )}
    </Formik>
  );
};

export default WriteReviewForm;
