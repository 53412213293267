import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Page from "src/components/Page";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CollapseAlert from "src/components/Alert";
import * as actions from "src/actions/auth";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {},
});

const AuthResetView = ({ className, ...rest }) => {
  const classes = useStyles();
  const { userId, token } = useParams();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [values, setValues] = useState({
    password: "",
    confirm: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Page className={classes.root} title="Password Reset">
      <Container maxWidth="lg">
        <Box mt={3}>
          <form className={clsx(classes.root, className)} {...rest}>
            <Card>
              <CardHeader title="Password Reset" />
              <Divider />
              <CardContent>
                <CollapseAlert
                  message={message}
                  severity={severity}
                  active={error}
                  onClose={() => {
                    setError(false);
                    setDisable(false);
                    setMessage("");
                  }}
                />
                <TextField
                  fullWidth
                  label="New Password"
                  margin="normal"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Confirm New password"
                  margin="normal"
                  name="confirm"
                  onChange={handleChange}
                  type="password"
                  value={values.confirm}
                  variant="outlined"
                />
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disable}
                  onClick={() => {
                    // check that values match
                    if (values.password !== values.confirm) {
                      setMessage("Passwords do not match!");
                      setSeverity("error");
                      setError(true);
                      setDisable(true);
                      return;
                    }
                    actions
                      .resetPassword(token, userId, values.password)
                      .then((response) => {
                        setMessage(
                          "Password change successful. Log in with your new password."
                        );
                        setSeverity("info");
                        setError(true);
                        setDisable(true);
                      })
                      .catch((error) => {
                        setMessage(
                          "Something is not right. Please check that the reset link is correct"
                        );
                        setSeverity("error");
                        setError(true);
                        setDisable(true);
                      });
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Card>
          </form>
        </Box>
        {disable && (
          <Box marginTop={2}>
            <Typography color="secondary" variant="body1">
              <Link component={RouterLink} to="/login" variant="h6">
                Go to Login.
              </Link>
            </Typography>
          </Box>
        )}
      </Container>
    </Page>
  );
};

AuthResetView.propTypes = {
  className: PropTypes.string,
};

export default AuthResetView;
