import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Card,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import * as actions from "src/actions/auth";
import { AssignmentTurnedInRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    borderRadius: 25,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
}));

const MyEnrollmentsList = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [enrollments, setEnrollments] = useState([]);
  const limit = 5;

  useEffect(() => {
    actions
      .getMyEnrollments()
      .then((response) => {
        setEnrollments(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Divider />
      <List component="nav">
        {enrollments.slice(0, limit).map((enrollment, i) => (
          <ListItem
            key={i}
            button
            onClick={() => {
              navigate(`/app/courses/${enrollment.course_slug}`);
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt="Course"
                src={enrollment.course_image}
                variant="rounded"
              >
                <AssignmentTurnedInRounded />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`Course: ${enrollment.course_name}`}
              secondary={`
                ${(
                  (100 * enrollment.max_progress) /
                  enrollment.progress_final
                ).toFixed(0)}% Complete`}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Card>
  );
};

MyEnrollmentsList.propTypes = {
  className: PropTypes.string,
  // setSize: PropTypes.func.isRequired,
  course: PropTypes.number.isRequired,
};

export default MyEnrollmentsList;
