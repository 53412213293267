import React from "react";
import { Box, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Resizer from "react-image-file-resizer";

export const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export function onKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      360,
      1024,
      "PNG",
      100,
      0,
      (blob) => {
        resolve(blob);
      },
      "file"
    );
  });

export function addDepositFee(amount) {
  // return +amount + Math.abs(amount) * 0.01;
  return Math.abs(amount) * 1.01;
}

export function paystackScript() {
  const src = "https://js.paystack.co/v1/inline.js";
  return new Promise(function(resolve, reject) {
    var script = document.createElement("script");
    script.src = src;
    script.addEventListener("load", function() {
      resolve();
    });
    script.addEventListener("error", function(e) {
      reject(e);
    });
    document.body.appendChild(script);
  });
}
