import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import * as actions from "src/actions/auth";
import CollapseAlert from "src/components/Alert";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(() => ({
  root: { borderRadius: 25 },
}));

const ProfileDetails = ({ className, userId, ...rest }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [values, setValues] = useState(undefined);
  const [accountType, setAccountType] = useState("0");

  useEffect(() => {
    actions.getUserProfileDetails(userId).then((response) => {
      setValues({
        email: response.data?.user,
      });
      setAccountType(response.data?.access);
    });
  }, [userId]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    actions
      .updateUserDetailsWith(userId, {
        access: `${accountType}`,
      })
      .then((response) => {
        setError(true);
        setSeverity("info");
        setAlertMessage("Updated!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (values === undefined) {
    return <Typography>Loading</Typography>;
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
      // onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader title="Profile" subheader="Update account type" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <CollapseAlert
              message={alertMessage}
              severity={severity}
              active={error}
              onClose={() => {
                setError(false);
              }}
            />
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                value={values.email}
                variant="outlined"
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel shrink id="simple-select-placeholder-label-label">
                  Choose account type
                </InputLabel>
                <Select
                  labelId="simple-select-placeholder-label-label"
                  id="simple-select-placeholder-label"
                  value={accountType}
                  onChange={(event) => {
                    setAccountType(event.target.value);
                  }}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value={"0"}>Student</MenuItem>
                  <MenuItem value={"1"}>Tutor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Update Account Type
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
