import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: { borderRadius: 25 },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Profile = ({ className, profileData, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={profileData.image} />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`${profileData.first_name} ${profileData.last_name}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {profileData.country}
          </Typography>
          <Grid align="center">
            {profileData.verified ? (
              <Typography color="secondary">Verified</Typography>
            ) : (
              <Typography color="secondary">Un-Verified</Typography>
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
