import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Container,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import AllUsers from "./UsersList";
import * as actions from "src/actions/auth";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const UsersListView = () => {
  const classes = useStyles();
  const [studentUsers, setStudentUsers] = useState([]);
  const [tutorUsers, setTutorUsers] = useState([]);
  const [tutorExpanded, setTutorExpanded] = useState(true);
  const [studentExpanded, setStudentExpanded] = useState(true);

  const handleTutorExpandClick = () => {
    setTutorExpanded(!tutorExpanded);
  };
  const handleStudentExpandClick = () => {
    setStudentExpanded(!studentExpanded);
  };

  useEffect(() => {
    actions
      .getTutorUsers()
      .then((response) => {
        setTutorUsers(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    actions
      .getStudentUsers()
      .then((response) => {
        setStudentUsers(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  return (
    <Page className={classes.root} title="Users">
      <Container maxWidth={false}>
        <Box margin={1}>
          <Card>
            <CardContent>
              <Typography color="textPrimary" variant="h5">
                Tutors
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: tutorExpanded,
                })}
                onClick={handleTutorExpandClick}
                aria-expanded={tutorExpanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>

            <Collapse in={tutorExpanded} timeout="auto" unmountOnExit>
              <Box mt={3}>
                <AllUsers users={tutorUsers} />
              </Box>
            </Collapse>
          </Card>
        </Box>
        <Box margin={1}>
          <Card>
            <CardContent>
              <Typography color="textPrimary" variant="h5">
                Students
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: studentExpanded,
                })}
                onClick={handleStudentExpandClick}
                aria-expanded={studentExpanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>

            <Collapse in={studentExpanded} timeout="auto" unmountOnExit>
              <Box mt={3}>
                <AllUsers users={studentUsers} />
              </Box>
            </Collapse>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default UsersListView;
