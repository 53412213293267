import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import * as actions from "src/actions/auth";
import { AssignmentTurnedInRounded } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: 25,
  },
  image: {
    height: 48,
    width: 48,
  },
});

const UserEnrollments = ({ className, userId, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [enrollments, setEnrollments] = useState([]);
  const limit = 5;

  useEffect(() => {
    actions
      .getUserEnrollments(userId)
      .then((response) => {
        setEnrollments(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, [userId]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        subtitle={`${enrollments.length} in total`}
        title="Enrollments"
      />
      <Divider />
      {enrollments.length > 0 ? (
        <List component="nav">
          {enrollments.slice(0, limit).map((enrollment, i) => (
            <ListItem
              key={i}
              button
              onClick={() => {
                navigate(`/app/courses/${enrollment.course_slug}`);
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Course"
                  src={enrollment.course_image}
                  variant="rounded"
                >
                  <AssignmentTurnedInRounded />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`Course: ${enrollment.course_name}`}
                secondary={`
                ${(
                  (100 * enrollment.max_progress) /
                  enrollment.progress_final
                ).toFixed(0)}% Complete`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          <ListItem>
            <Typography variant="caption" color="secondary">
              The user is not enrolled in any courses.
            </Typography>
          </ListItem>
        </List>
      )}
      <Divider />
      {/* <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={handleViewAllClick}
        >
          View all
        </Button>
      </Box> */}
    </Card>
  );
};

UserEnrollments.propTypes = {
  className: PropTypes.string,
};

export default UserEnrollments;
