import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import WriteReviewForm from "./WriteReviewForm";
import StarsIcon from "@material-ui/icons/Stars";
import NoAccessGrid from "src/components/NoAccessGrid";
import { API_URL } from "src/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ReviewGrid = ({ className, courseId, permitted }) => {
  const classes = useStyles();
  const [reviewContent, setContent] = useState([]);
  const [score, setValue] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };

    API_URL({
      method: "get",
      url: `courses/reviews/?course=${courseId}`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        setContent(response.data);
      })
      .catch((error) => {});
  }, [score, courseId]);

  const getAverageReview = () => {
    var total = 0;
    if (reviewContent.length < 1) {
      return 0.0;
    }
    reviewContent.forEach((element) => {
      total = total + element.score;
    });
    return total / reviewContent.length;
  };

  if (permitted) {
    return (
      <Grid>
        <Typography
          align="left"
          color="textSecondary"
          gutterBottom
          variant="caption"
        >
          REVIEWS:
        </Typography>
        <Grid item lg={3} sm={3} md={3} xs={3}>
          <Card className={clsx(classes.root, className)}>
            <CardContent>
              <Grid container >
                <StarsIcon color="secondary" />
                <Typography align="left" color="textSecondary" variant="h4">
                  {getAverageReview().toFixed(0)}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {reviewContent.map((content, index) => (
          <Box
            key={index}
            component="fieldset"
            mb={3}
            borderColor="transparent"
          >
            <Typography variant="h6" color="primary">
              {content.first_name} {content.last_name}:
            </Typography>
            <Grid>
              <Typography component="legend">{content.body}</Typography>
              <Rating
                name="course_rating"
                readOnly
                value={content.score}
                precision={0.5}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
            </Grid>
          </Box>
        ))}
        <WriteReviewForm
          courseId={courseId}
          score={score}
          setValue={setValue}
        />
      </Grid>
    );
  }

  return <NoAccessGrid />;
};

export default ReviewGrid;
