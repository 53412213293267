import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import Password from "./Password";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SettingsView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        {localStorage.getItem("user") ? (
          <Box mt={3}>
            <Password />
          </Box>
        ) : (
          <Grid>
            <Typography>You are not signed in.</Typography>
            <Link component={RouterLink} to={`/register/`} variant="h6">
              Sign in/Sign up
            </Link>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default SettingsView;
