import React, { useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, TextField, makeStyles } from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";
import ActionItem from "./ActionItem";
import AddBoxIcon from "@material-ui/icons/AddBox";
import OptionForm from "./OptionForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
    marginLeft: "30px",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const QuestionForm = ({
  className,
  question,
  quiz,
  action,
  order,
  setUpdated,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");
  const [newOption, setNewOption] = useState(false);

  const getNextOrder = () => {
    if (action === "update") {
      return question && question.question_order;
    }
    if (action === "add") {
      return order + 1;
    }
    return question && question.question_order;
  };

  const handleButtons = (values, resetForm) => {
    const handleAdd = () => {
      const formData = new FormData();
      formData.append("question_order", values.question_order);
      formData.append("label", values.label);
      formData.append("quiz", quiz);
      if (values.video_url) {
        formData.append("video_url", values.video_url);
      }

      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "post",
        url: `courses/questions/`,
        data: formData,
        headers: headers,
      })
        .then((response) => {
          resetForm();
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleDelete = () => {
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "delete",
        url: `courses/questions/${question.id}/`,
        data: JSON.stringify({}),
        headers: headers,
      })
        .then((response) => {
          setErrorMessage("Content Removed!");
          setErrorSeverity("info");
          setError(true);
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleUpdate = () => {
      const formData = new FormData();
      formData.append("question_order", values.question_order);
      formData.append("name", values.name);
      formData.append("label", values.label);
      formData.append("quiz", quiz);
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "patch",
        url: `courses/questions/${question.id}/`,
        data: formData,
        headers: headers,
      })
        .then((response) => {
          setErrorMessage("Question Updated!");
          setErrorSeverity("info");
          setError(true);
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    if (action === "update") {
      return (
        <Grid>
          <Button
            color="secondary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleDelete}
          >
            Remove
          </Button>
          <Button
            color="primary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Grid>
      );
    }

    return (
      <Button
        color="secondary"
        disabled={false}
        size="small"
        variant="contained"
        onClick={handleAdd}
      >
        Add
      </Button>
    );
  };

  const handleNewOption = () => {
    setNewOption(!newOption);
  };

  return (
    <Grid>
      <Formik
        initialValues={{
          question_order: getNextOrder(),
          label: question ? question.label : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required("A quiz must have a name"),
          description: Yup.string()
            .max(1000)
            .required("A quiz needs a description"),
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          touched,
          values,
        }) => (
          <form onSubmit={() => resetForm()}>
            <TextField
              error={Boolean(touched.question_order && errors.question_order)}
              fullWidth
              helperText={touched.question_order && errors.question_order}
              label=""
              margin="normal"
              name="question_order"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.question_order}
              disabled
              size="medium"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              error={Boolean(touched.label && errors.label)}
              fullWidth
              helperText={touched.label && errors.label}
              label="Question:"
              margin="normal"
              name="label"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.label}
              variant="outlined"
              size="medium"
              multiline
              rowsMax={5}
            />
            <CollapseAlert
              active={error}
              severity={errorSeverity}
              message={errorMessage}
              onClose={() => {
                setError(false);
              }}
            />
            <Box>{handleButtons(values, resetForm)}</Box>
          </form>
        )}
      </Formik>
      {question && (
        <Grid container>
          {question.options.map((option, index) => (
            <OptionForm
              key={index}
              question={question.id}
              option={option}
              action="update"
              setUpdated={setUpdated}
            />
          ))}
        </Grid>
      )}
      {action !== "add" && (
        <ActionItem
          icon={AddBoxIcon}
          title="Add Option"
          onClick={handleNewOption}
          size="small"
        />
      )}
      {newOption && action === "update" && (
        <Grid>
          <OptionForm
            className={clsx(classes.root, className)}
            question={question.id}
            action="add"
            setUpdated={setUpdated}
            order={question.options.length}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionForm;
