import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import getInitials from "src/utils/getInitials";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
}));

const MyCourseUsers = ({ className, setSize, course, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    actions
      .getCourseEnrollments(course)
      .then((response) => {
        setUsers(response.data);
        setSize(response.data.length);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, [course, setSize]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Progress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(page * limit, page * limit + limit).map(
                (user, index) =>
                  localStorage.getItem("user_id") !== user.id && (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar className={classes.avatar} src={user.image}>
                            {getInitials(user.user)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {user.user_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {(
                          (100 * user.max_progress) /
                          user.progress_final
                        ).toFixed(0)}
                        %
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={users.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

MyCourseUsers.propTypes = {
  className: PropTypes.string,
  setSize: PropTypes.func.isRequired,
  course: PropTypes.number.isRequired,
};

export default MyCourseUsers;
