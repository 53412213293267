import React from "react";
import { Box } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import Prism from "prismjs";
import math from "remark-math";
import "katex/dist/katex.min.css";

// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
// import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
// import { InlineMath, BlockMath } from "react-katex";

// const renderers = {
//   code: ({ language, value }) => {
//     return (
//       <SyntaxHighlighter style={darcula} language={language} children={value} />
//     );
//   },
//   inlineMath: ({ value }) => <InlineMath math={value} />,
//   math: ({ value }) => <BlockMath math={value} />,
// };

// Alternative renderer
function BlankCodeBlock({ value }) {
  return <pre className="language-">{value || ""}</pre>;
}
function CodeBlock({ language, value }) {
  // 1. no language was typed
  // or 2. language doesnt exist
  if (!language || !Prism.languages[language] || !value)
    return <BlankCodeBlock value={value} />;

  var html = Prism.highlight(value, Prism.languages[language]);
  var cls = "language-" + language;

  return (
    <pre className={cls}>
      <code dangerouslySetInnerHTML={{ __html: html }} className={cls} />
    </pre>
  );
}

// TODO: fix markdown rendering (prism preferred)
const DisplayMarkdown = ({ className, children }) => {
  return (
    <Box margin={2}>
      <ReactMarkdown
        plugins={[gfm, math]}
        className={className}
        // renderers={renderers} // alternative
        renderers={{ code: CodeBlock }}
        children={children}
      />
    </Box>
  );
};

export default DisplayMarkdown;
