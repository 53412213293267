import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import getInitials from "src/utils/getInitials";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

const UsersList = ({ className, users, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(page * limit, page * limit + limit).map(
                (user, index) =>
                  localStorage.getItem("user_id") !== user.id && (
                    <TableRow
                      hover
                      key={index}
                      onClick={() => {
                        navigate(`/admin/user_details/${user.id}`);
                      }}
                    >
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar className={classes.avatar} src={user.image}>
                            {getInitials(user.user)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {user.first_name + " " + user.last_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{user.country}</TableCell>
                      <TableCell>{user.status}</TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={users.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </Card>
  );
};

UsersList.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default UsersList;
