import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Avatar,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import * as actions from "src/actions/auth";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: 25,
  },
  image: {
    height: 48,
    width: 48,
  },
});

const MyCourses = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const limit = 5;

  useEffect(() => {
    actions
      .getMyCourses()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  const getDuration = (given_date) => {
    const now = new Date().getTime();
    const created = new Date(given_date).getTime();
    const diffTime = Math.abs(now - created);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const days = diffDays > 1 ? "days" : "day";
    return `${diffDays} ${days}`;
  };

  const handleViewAllClick = () => {
    navigate("/app/my_courses");
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader subtitle={`${courses.length} in total`} title="My Courses" />
      <Divider />
      {courses.length > 0 ? (
        <List component="nav">
          {courses.slice(0, limit).map((course, i) => (
            <ListItem
              key={i}
              button
              onClick={() => {
                navigate(`/app/courses/${course.slug}`);
              }}
            >
              <ListItemAvatar>
                <Avatar alt="Product" src={course.image} variant="rounded" />
              </ListItemAvatar>
              <ListItemText
                primary={course.name}
                secondary={`Created: ${getDuration(course.created)} ago`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    navigate(`/app/edit_course/${course.slug}`);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          <ListItem>
            <Typography variant="caption" color="secondary">
              You are yet to create a course!
            </Typography>
          </ListItem>
        </List>
      )}
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={handleViewAllClick}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

MyCourses.propTypes = {
  className: PropTypes.string,
};

export default MyCourses;
