import React, { useState, useEffect } from "react";
import { Grid, Container, Typography, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import * as actions from "src/actions/auth";
// import MyScoresList from "src/views/reports/DashboardView/MyScores";
import MyScoresList from "./MyScoresList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    borderRadius: 25,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const MyScoresListView = () => {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    actions
      .getMyEnrollments()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  return (
    <Page className={classes.root} title="Scores">
      <Container maxWidth={false}>
        <Grid>
          <Typography variant="h2">My Scores List</Typography>
        </Grid>
        <Grid>
          {courses.map((course, index) => (
            <MyScoresList course={course} key={index} />
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default MyScoresListView;
