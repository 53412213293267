import React, { useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";
import DisplayMarkdown from "src/components/DisplayMarkdown";
import QuestionForm from "./QuestionForm";
import ActionItem from "./ActionItem";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
    justifyContent: "center",
    fontFamily: "Roboto, Helvetica Neue",
    marginLeft: "30px",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const QuizForm = ({
  className,
  course,
  quiz,
  action,
  order,
  setUpdated,
  setNewQuiz,
  reorderContent,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");
  const [newQuestion, setNewQuestion] = useState(false);

  const getNextOrder = () => {
    if (action === "update") {
      return quiz && quiz.serial_order;
    }
    if (action === "add") {
      return order + 1;
    }
    return quiz && quiz.serial_order;
  };

  const handleButtons = (values, resetForm) => {
    const handleAdd = () => {
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "post",
        url: `courses/quizzes/`,
        data: JSON.stringify({
          serial_order: values.serial_order,
          course: course,
          name: values.name,
          description: values.description,
        }),
        headers: headers,
      })
        .then((response) => {
          resetForm();
          setNewQuiz(false);
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleDelete = () => {
      reorderContent(quiz.serial_order);
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "delete",
        url: `courses/quizzes/${quiz.id}/`,
        data: JSON.stringify({}),
        headers: headers,
      })
        .then((response) => {
          setErrorMessage("Content Removed!");
          setErrorSeverity("info");
          setError(true);
          setNewQuiz(false);
          setUpdated((update) => {
            return update + 1;
          });
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    const handleUpdate = () => {
      const formData = new FormData();
      formData.append("serial_order", values.serial_order);
      formData.append("course", course);
      formData.append("name", values.name);
      formData.append("description", values.description);
      const token = localStorage.getItem("token");
      const headers = {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      };
      API_URL({
        method: "patch",
        url: `courses/quizzes/${quiz.id}/`,
        data: formData,
        headers: headers,
      })
        .then((response) => {
          setErrorMessage("Quiz Updated!");
          setErrorSeverity("info");
          setError(true);
        })
        .catch((error) => {
          console.log("Error occured: ", error);
        });
    };

    if (action === "update") {
      return (
        <Grid>
          <Button
            color="secondary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleDelete}
          >
            Remove
          </Button>
          <Button
            color="primary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Grid>
      );
    }
    return (
      <Button
        color="secondary"
        disabled={false}
        size="small"
        variant="contained"
        onClick={handleAdd}
      >
        Add
      </Button>
    );
  };

  const handleNewQuestion = () => {
    setNewQuestion(!newQuestion);
  };

  return (
    <Grid>
      <Formik
        initialValues={{
          serial_order: getNextOrder(),
          name: quiz ? quiz.name : "",
          description: quiz ? quiz.description : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required("A quiz must have a name"),
          description: Yup.string().max(1000),
          // .required("A quiz needs a description"),
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          touched,
          values,
        }) => (
          <form onSubmit={() => resetForm()}>
            <TextField
              error={Boolean(touched.serial_order && errors.serial_order)}
              fullWidth
              helperText={touched.serial_order && errors.serial_order}
              label=""
              margin="normal"
              name="serial_order"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.serial_order}
            />
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Quiz Title"
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.description && errors.description)}
              fullWidth
              helperText={touched.description && errors.description}
              id="filled-multiline-static"
              label="(Optional): Instructions or some explanation."
              margin="normal"
              name="description"
              multiline
              rowsMax={10}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              variant="outlined"
            />
            <Grid margin={2}>
              <Typography variant="h6">Preview:</Typography>
              <DisplayMarkdown
                className={clsx(classes.root)}
                children={values.description}
              />
            </Grid>
            <CollapseAlert
              active={error}
              severity={errorSeverity}
              message={errorMessage}
              onClose={() => {
                setError(false);
              }}
            />
            <Box my={3}>{handleButtons(values, resetForm)}</Box>
          </form>
        )}
      </Formik>
      {quiz && (
        <Grid container spacing={2}>
          {quiz.questions.map((question, index) => (
            <Grid item xs={6} key={index}>
              <QuestionForm
                quiz={quiz.id}
                question={question}
                action="update"
                setUpdated={setUpdated}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid>
        {action !== "add" && (
          <ActionItem
            icon={AddBoxIcon}
            title="Add Question"
            onClick={handleNewQuestion}
            size="small"
          />
        )}
      </Grid>
      {newQuestion && action === "update" && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QuestionForm
              className={clsx(classes.root, className)}
              quiz={quiz.id}
              action="add"
              setUpdated={setUpdated}
              order={quiz.questions.length}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default QuizForm;
