import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import WriteCommentForm from "./WriteCommentForm";
import WriteReplyForm from "./WriteReplyForm";
import { API_URL } from "src/constants";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Interactions = ({ className, contentId, page, slug, ...rest }) => {
  const classes = useStyles();
  const [prefix, setPrefix] = useState("Prefix");
  const [renderReply, setRenderReply] = useState(false);
  const [comments, setComments] = useState([]);
  const [updated, setUpdated] = useState(0);

  const postNotification = (body) => {
    actions
      .createNotification(
        `${localStorage.getItem("first_name")} ${localStorage.getItem(
          "last_name"
        )} commented in`,
        body,
        `/app/courses/${slug}/${page}`,
        slug
      )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };

    API_URL({
      method: "get",
      url: `courses/comments/?content=${contentId}`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        // create notification
        setComments(response.data);
      })
      .catch((error) => {});
  }, [updated, contentId]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        {comments.map((comment, index) => (
          <Box key={index}>
            <Typography variant="h6" color="primary">
              {comment.first_name} {comment.last_name}
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {comment.body}
            </Typography>
            <Grid align="right">
              <Button
                color="secondary"
                disabled={false}
                size="small"
                onClick={() => {
                  setPrefix(`${comment.first_name} ${comment.last_name}`);
                  setRenderReply(!renderReply);
                }}
              >
                {!renderReply ? "Reply" : "Cancle"}
              </Button>
            </Grid>
          </Box>
        ))}
        {renderReply ? (
          <WriteReplyForm
            content={contentId}
            bodyPrefix={`@${prefix}, `}
            setUpdated={setUpdated}
            postNotification={postNotification}
          />
        ) : (
          <WriteCommentForm
            content={contentId}
            setUpdated={setUpdated}
            postNotification={postNotification}
          />
        )}
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
    </Card>
  );
};

Interactions.propTypes = {
  className: PropTypes.string,
  comments: PropTypes.array.isRequired,
};

export default Interactions;
