import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { API_URL } from "src/constants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
}));

const BreadCrumbsVisual = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataLabels, setDataLabels] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [actionsData, setActionsData] = useState([]);
  const [interval, setInterval] = useState(7);

  useEffect(() => {
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    API_URL({
      method: "get",
      url: `analytics/breadcrumbs/?days=${interval}`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        setDataLabels(response.data.dates);
        setUsersData(response.data.users);
        setActionsData(response.data.actions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [interval]);

  const dailyUniqueData = {
    labels: dataLabels,
    datasets: [
      {
        label: "Unique Users",
        data: usersData,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        yAxisID: "y-axis-1",
      },
      {
        label: "Actions",
        data: actionsData,
        fill: true,
        borderColor: "#742774",
        yAxisID: "y-axis-2",
      },
    ],
  };

  const multiLineOptions = {
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          color: "#742774",
          gridLines: {
            drawOnArea: false,
          },
        },
      ],
    },
  };

  const handleChange = (event) => {
    setInterval(event.target.value);
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Daily Unique Users"
        action={
          <FormControl
            // variant="filled"
            className={classes.formControl}
            size="small"
          >
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={interval}
              onChange={handleChange}
            >
              <MenuItem value={7}>7 Days</MenuItem>
              <MenuItem value={30}>30 Days</MenuItem>
              <MenuItem value={90}>90 Days</MenuItem>
              <MenuItem value={365}>1 Year</MenuItem>
            </Select>
          </FormControl>
        }
      />
      <Divider />
      <CardContent>
        <Line data={dailyUniqueData} options={multiLineOptions} />
      </CardContent>
    </Card>
  );
};

export default BreadCrumbsVisual;
