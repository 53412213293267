import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, Hidden, IconButton, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import * as actions from "src/actions/auth";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import OutlineList from "./OutlineList";
import Dialog from "@material-ui/core/Dialog";
import CollapseAlert from "src/components/Alert";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  courseId,
  slug,
  price,
  ownership,
  setEnrolled,
  setPage,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { contents, page } = rest;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("info");

  const handleButtons = () => {
    if (ownership === "guest") {
      return (
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={() => {
            navigate(`/register`);
          }}
        >
          Sign Up
        </Button>
      );
    }

    if (ownership === "owner") {
      return (
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={() => {
            navigate(`/app/edit_course/${slug}`);
          }}
        >
          Edit Course
        </Button>
      );
    }

    if (ownership === "non-enrolled") {
      return (
        // <Button
        //   color="secondary"
        //   variant="contained"
        //   size="small"
        //   onClick={() => {
        //     actions
        //       .postEnrollment(courseId)
        //       .then((response) => {
        //         // Notify course creator of enrollment
        //         actions
        //           .createNotification(
        //             `${localStorage.getItem(
        //               "first_name"
        //             )} ${localStorage.getItem(
        //               "first_name"
        //             )} enrolled in your course:`,
        //             "Go check it out",
        //             "/app/my_courses",
        //             slug
        //           )
        //           .then((response) => {})
        //           .catch((error) => {
        //             console.log(error);
        //           });
        //       })
        //       .catch((error) => {
        //         console.log(error);
        //       });
        //     setEnrolled(true);
        //   }}
        // >
        //   ENROLL
        // </Button>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={() => {
            actions.makePayment(
              courseId,
              price,
              function(response) {
                //this happens after the payment is completed successfully
                var reference = response.reference;
                // alert("Payment complete! Reference: " + reference);

                setError(true);
                setErrorMessage("Payment processed.");
                setErrorSeverity("info");
                // Make an AJAX call to your server with the reference to verify the transaction
                actions
                  .confirmPayment(reference, courseId)
                  .then((response) => {
                    actions
                      .postEnrollment(courseId)
                      .then((response) => {
                        setEnrolled(true);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              },
              function() {
                // alert("Transaction was not completed, window closed.");
                setError(true);
                setErrorMessage(
                  "Transaction was not completed, window closed."
                );
                setErrorSeverity("warning");
              }
            );
          }}
        >
          Enroll
        </Button>
      );
    }
  };

  const handleClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Hidden smUp>
          <IconButton
            color="inherit"
            onClick={() => setMobileNavOpen(!isMobileNavOpen)}
            size="small"
          >
            <MenuOpenIcon />
          </IconButton>
        </Hidden>
        <Dialog
          open={isMobileNavOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          {isMobileNavOpen && (
            <OutlineList
              className={clsx(classes.root, className)}
              contents={contents}
              page={page}
              setPage={setPage}
              afterClick={handleClose}
            />
          )}
        </Dialog>
        <CollapseAlert
          active={error}
          severity={errorSeverity}
          message={errorMessage}
          onClose={() => {
            setError(false);
          }}
        />
        {handleButtons()}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  courseId: PropTypes.number,
  slug: PropTypes.string,
};

export default Toolbar;
