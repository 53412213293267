import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import * as actions from "src/actions/auth";
import BioCard from "src/components/BioCard";
import ProfileCard from "src/components/ProfileCard";
import UserEnrollments from "src/components/UserEnrollments";
import CoursesListCard from "src/components/CoursesListCard";
import ProfileDetails from "./ProfileEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  courseCard: {
    height: "100%",
  },
}));

const UserDetails = () => {
  const classes = useStyles();
  const { userId } = useParams();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    // get user profile
    actions
      .getUserProfileDetails(userId)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  return (
    <Page className={classes.root} title="User Detail">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item lg={4} md={6} xs={12}>
            <Grid container alignContent="center" spacing={1}>
              <Grid item xs={12}>
                <ProfileCard profileData={userData} />
              </Grid>
              <Grid item xs={12}>
                <BioCard profileData={userData} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Grid container alignItems="stretch" spacing={1}>
              <Grid item xs={12}>
                <UserEnrollments userId={userId} />
              </Grid>
            </Grid>
            <Grid container alignItems="stretch" spacing={1}>
              <Grid item xs={12}>
                <CoursesListCard creatorId={userId} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box margin={1}>
          <ProfileDetails userId={userId} />
        </Box>
      </Container>
    </Page>
  );
};

export default UserDetails;
