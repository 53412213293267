import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Grid,
  makeStyles,
  Link,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import Profile from "./Profile";
import ProfileDetails from "./ProfileDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Account = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        {localStorage.getItem("user") ? (
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Profile />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails />
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography>You are not signed in.</Typography>
            <Link component={RouterLink} to={`/register/`} variant="h6">
              Sign in/Sign up
            </Link>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default Account;
