import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  makeStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Rating from "@material-ui/lab/Rating";
import { API_URL } from "src/constants";
import * as actions from "src/actions/auth";

// icons:
import CloseIcon from "@material-ui/icons/Close";
import BookIcon from "@material-ui/icons/Book";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import GradeIcon from "@material-ui/icons/Grade";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: 25,
  },
  image: {
    height: 48,
    width: 48,
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: "primary",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          color="primary"
          size="small"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CourseSynopsisDialog = ({
  className,
  isOpen,
  setOpenSynopsis,
  course,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [reviewContent, setReviewContent] = useState([]);
  const [creatorData, setCreatorData] = useState({});

  const getAverageReview = () => {
    var total = 0;
    if (reviewContent.length < 1) {
      return 0.0;
    }
    reviewContent.forEach((element) => {
      total = total + element.score;
    });
    return total / reviewContent.length;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };

    API_URL({
      method: "get",
      url: `courses/reviews/?course=${course.id}`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        setReviewContent(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // get course creator profile
    actions
      .getCreatorId(course.creator)
      .then((response) => {
        // use the id to get creator profile
        actions
          .getUserProfileDetailsWithProfileId(response.data[0].id)
          .then((response) => {
            setCreatorData(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [course.id, course.creator]);

  const handleClose = () => {
    setOpenSynopsis(false);
  };

  return (
    <Grid className={clsx(classes.root, className)} container>
      <Grid>
        <Dialog
          // fullScreen
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="course-synopsis-dialog"
          fullWidth
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle
            id="course-synopsis-title"
            onClose={handleClose}
            color="primary"
          >
            {course.name}
          </DialogTitle>
          <DialogContent>
            <CardMedia component="img" image={course.image} />
            <DialogContentText>
              <Box margin={1}>
                <Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Link
                      component={RouterLink}
                      to={`/app/user_profile/${creatorData?.user_id}/`}
                    >
                      <Typography
                        variant="body2"
                        color="primary"
                      >{`By ${course.creator_name}`}</Typography>
                    </Link>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Grid container>
                      <Typography color="secondary" variant="h4">
                        {course.price === 0 ? "Free" : `NGN ${course.price}`}
                      </Typography>
                      <Typography>*</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography color="textSecondary" variant="body2">
                    {course.description}
                  </Typography>
                </Grid>
                <Divider />
              </Box>
              <Box margin={1}>
                <Grid>
                  <Typography variant="body2" color="primary">
                    Course Features:
                  </Typography>
                </Grid>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <BookIcon size="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Lessons"
                      secondary={course.contents.length}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <BeenhereIcon size="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Quizzes"
                      secondary={course.quizzes.length}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <GradeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Certificate"
                      secondary="Coming soon..."
                    />
                  </ListItem>
                </List>
              </Box>
              <Box margin={1}>
                <Grid>
                  <Typography
                    variant="body2"
                    color="primary"
                    component="legend"
                  >
                    Rating ({reviewContent.length}):
                  </Typography>
                  {/* TODO: show top 3 reviews */}
                  <Rating
                    name="course_rating"
                    size="large"
                    readOnly
                    value={getAverageReview()}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  />
                </Grid>
              </Box>
              <Typography variant="caption">
                *A processing fee of 1% is added at checkout.
              </Typography>
            </DialogContentText>
            <Grid></Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                navigate(`/app/courses/${course.slug}`);
              }}
              size="small"
              color="secondary"
              variant="contained"
            >
              Go to Course
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default CourseSynopsisDialog;
