import React, { useState, useEffect } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import { Typography } from "@material-ui/core";
import LineChart from "./LineChart";
import BreadCrumbsVisual from "./BreadCrumbsVisual";
import * as actions from "src/actions/auth";
import StatCard from "src/components/StatCard";

import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  courseCard: {
    height: "100%",
  },
}));

const AdminDashboard = () => {
  const classes = useStyles();
  const [isStaff, setIsStaff] = useState(false);
  const [numStudents, setNumStudents] = useState(0);
  const [numTutors, setNumTutors] = useState(0);

  useEffect(() => {
    // get user profile
    actions
      .getMyProfileDetails()
      .then((response) => {
        setIsStaff(response.data.is_staff);
      })
      .catch((error) => {
        console.log(error);
      });

    actions
      .getStudentUsers()
      .then((response) => {
        setNumStudents(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });

    actions
      .getTutorUsers()
      .then((response) => {
        setNumTutors(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Page className={classes.root} title="Admin Dashboard">
      <Container maxWidth={false}>
        {!isStaff ? (
          <Grid>
            <Typography color="textSecondary">
              You do not have permission to view this page!
            </Typography>
          </Grid>
        ) : (
          <Grid>
            <Grid>
              <Typography variant="h1">Admin Dashboard</Typography>
            </Grid>
            <Box>
              <Grid container spacing={1}>
                <Grid item lg={3} sm={6} xs={12}>
                  <StatCard
                    name="Tutors"
                    number={numTutors}
                    link="/admin/users_list"
                    icon={<SupervisorAccountIcon />}
                  />
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <StatCard
                    name="Students"
                    number={numStudents}
                    link="/admin/users_list"
                    icon={<PeopleOutlineIcon />}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {/* <PieChart /> <BarChart /> */}
                <Grid item lg={6} md={12} sm={12} sx={12}>
                  <LineChart />
                </Grid>
                <Grid item lg={6} md={12} sm={12} sx={12}>
                  <BreadCrumbsVisual />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default AdminDashboard;
