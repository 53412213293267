import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ContentForm from "./ContentForm";
import QuizForm from "./QuizForm";
import * as actions from "src/actions/auth";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ActionItem from "./ActionItem";
import CourseUpdateForm from "./CourseUpdateFrom";
import { API_URL } from "src/constants";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  courseCard: {
    height: "100%",
  },
}));

const EditCourseView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  let { slug } = useParams();
  const [newContent, setNewContent] = useState(false);
  const [newQuiz, setNewQuiz] = useState(false);
  const [courseContent, setCourseContent] = useState(undefined);
  const [allContent, setAllContent] = useState(undefined);
  const [order, setOrder] = useState(0);
  const [updated, setUpdated] = useState(0);
  const [courseId, setCourseId] = useState(undefined);
  const [contentAddTitle, setContentAddTitle] = useState("Add Content/Quiz");

  const createOrder = (contents, quizzes) => {
    const totalLen = contents.length + quizzes.length;
    var allContent = new Array(totalLen);
    contents.forEach((content) => {
      allContent[content.serial_order - 1] = { type: "content", data: content };
    });
    quizzes.forEach((content) => {
      allContent[content.serial_order - 1] = { type: "quiz", data: content };
    });

    setOrder(allContent.length);
    setAllContent(allContent);
  };

  const patchContentOrder = (content, newOrder) => {
    const formData = new FormData();
    formData.append("course", content.data.course);
    formData.append("serial_order", newOrder);
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "patch",
      url: `courses/contents/${content.data.id}/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {})
      .catch((error) => {
        console.log("Error occured: ", error);
      });
  };

  const patchQuizOrder = (content, newOrder) => {
    const formData = new FormData();
    formData.append("course", content.data.course);
    formData.append("serial_order", newOrder);
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "patch",
      url: `courses/quizzes/${content.data.id}/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {})
      .catch((error) => {
        console.log("Error occured: ", error);
      });
  };

  const reorderContent = (serialOrder) => {
    allContent.splice(serialOrder - 1, 1);
    // for all elements patch the serial order
    allContent.forEach((content, index) => {
      if (content.type === "content") {
        patchContentOrder(content, index + 1);
      }
      if (content.type === "quiz") {
        patchQuizOrder(content, index + 1);
      }
    });
  };

  const loopContents = (contents) => {
    let contentList = [];
    contents.forEach((content, index) => {
      if (content.type === "content") {
        contentList.push(
          <Accordion margin={3} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="textPrimary" variant="h5">
                {content.data.serial_order}: {content.data.name}
              </Typography>
            </AccordionSummary>
            <Box margin={3} key={index}>
              <ContentForm
                course={courseId}
                // course={slug}
                content={content.data}
                action="update"
                setUpdated={setUpdated}
                setNewContent={setNewContent}
                reorderContent={reorderContent}
              />
            </Box>
          </Accordion>
        );
      }
      if (content.type === "quiz") {
        contentList.push(
          <Accordion margin={3} key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="textPrimary" variant="h5">
                {content.data.serial_order}: {content.data.name}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                {" [quiz]"}
              </Typography>
            </AccordionSummary>
            <Box margin={3} key={index}>
              <QuizForm
                course={courseId}
                quiz={content.data}
                action="update"
                setUpdated={setUpdated}
                setNewQuiz={setNewQuiz}
                reorderContent={reorderContent}
              />
            </Box>
          </Accordion>
        );
      }
    });
    return contentList;
  };

  useEffect(() => {
    actions.postBreadcrumb(
      "post",
      `courses/courses/`,
      `in course edit: ${slug}`
    );
    actions
      .getCourseDetail(slug)
      .then((response) => {
        setCourseId(response.data.id);
        setCourseContent(response.data);
        createOrder(response.data.contents, response.data.quizzes);
        setContentAddTitle("Add Content/Quiz");
      })
      .catch((error) => {
        console.log("Read error: ", error);
      });
  }, [slug, updated]);

  const handleNewContent = () => {
    if (
      (contentAddTitle === "Add Content/Quiz" && newContent) ||
      contentAddTitle === "Add Quiz"
    ) {
      setContentAddTitle("Add Content");
    } else if (
      (contentAddTitle === "Add Content/Quiz" && newQuiz) ||
      contentAddTitle === "Add Content"
    ) {
      setContentAddTitle("Add Quiz");
    } else {
      setContentAddTitle("Add Quiz");
    }
    var newState = !newContent;
    setNewContent(newState);
    setNewQuiz(!newState);
  };

  // const handleNewQuiz = () => {
  //   var newState = !newQuiz;
  //   setNewQuiz(newState);
  //   setNewContent(!newState);
  // };

  if (courseContent === undefined) {
    // TODO: develop loading component, or splash screen
    return (
      <Typography variant="h5" color="textSecondary">
        Loading...
      </Typography>
    );
  }

  if (allContent === undefined) {
    // TODO: develop loading component, or splash screen
    return (
      <Typography variant="h5" color="textSecondary">
        Loading...
      </Typography>
    );
  }

  return (
    <Page className={classes.root} title="Edit Course">
      <Container>
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Edit Course
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Make a change, make it better.
          </Typography>
        </Box>
        <CourseUpdateForm courseId={slug} />
        <Card>{loopContents(allContent)}</Card>
        <ActionItem
          icon={AddBoxIcon}
          title={contentAddTitle}
          onClick={handleNewContent}
          size="small"
        />
        {/* <ActionItem
          icon={AddBoxIcon}
          title="Add Quiz"
          onClick={handleNewQuiz}
          size="small"
        /> */}
        {newContent && (
          <Card>
            <Box margin={3}>
              <ContentForm
                className={classes.root}
                course={courseId}
                action="add"
                order={order}
                setUpdated={setUpdated}
                setNewContent={setNewContent}
              />
            </Box>
          </Card>
        )}
        {newQuiz && (
          <Card>
            <Box margin={3}>
              <QuizForm
                className={classes.root}
                course={courseId}
                action="add"
                order={order}
                setUpdated={setUpdated}
                setNewQuiz={setNewQuiz}
              />
            </Box>
          </Card>
        )}
        <Grid>
          <ActionItem
            icon={ArrowBackIcon}
            title="Done"
            onClick={() => {
              navigate(`/app/courses/${slug}`);
            }}
            size="small"
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default EditCourseView;
