import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Page from "src/components/Page";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
} from "@material-ui/core";
import CollapseAlert from "src/components/Alert";
import * as actions from "src/actions/auth";

const useStyles = makeStyles({
  root: {},
});

const AuthResetView = ({ className, ...rest }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  function validateEmail(email) {
    // eslint-disable-next-line
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result === true) {
      return true;
    } else {
      return false;
    }
  }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Page className={classes.root} title="Forgot Password">
      <Container maxWidth="lg">
        <Box mt={3}>
          <form
            className={clsx(classes.root, className)}
            {...rest}
            onKeyDown={onKeyDown}
            noValidate
            autoComplete="off"
          >
            <Card>
              <CardHeader
                title="Forgot your password"
                subheader="Enter your registered email address and we will send you a password reset link."
              />
              <Divider />
              <CardContent>
                <CollapseAlert
                  message={message}
                  severity={severity}
                  active={error}
                  onClose={() => {
                    setError(false);
                    // setDisable(false);
                    setMessage("");
                  }}
                />
                <TextField
                  fullWidth
                  label="Email address:"
                  margin="normal"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                  disabled={disable}
                />
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disable}
                  onClick={() => {
                    if (!validateEmail(values.email)) {
                      setMessage("Invalid email address");
                      setSeverity("error");
                      setError(true);
                      setDisable(true);
                      return;
                    }
                    actions
                      .forgotPassword(values.email)
                      .then((response) => {
                        // show success message
                        setMessage(
                          "Check your email. If the entered email is registered, you will receive a reset link."
                        );
                        setSeverity("info");
                        setError(true);
                        setDisable(true);
                      })
                      .catch((error) => {
                        setMessage(
                          "Check your email. If the entered email is registered, you will receive a reset link."
                        );
                        setSeverity("info");
                        setError(true);
                        setDisable(true);
                      });
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Card>
          </form>
        </Box>
      </Container>
    </Page>
  );
};

AuthResetView.propTypes = {
  className: PropTypes.string,
};

export default AuthResetView;
