import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, makeStyles } from "@material-ui/core";
import * as actions from "src/actions/auth";
import Page from "src/components/Page";
import ProfileCard from "src/components/ProfileCard";
import BioCard from "src/components/BioCard";
import CoursesListCard from "src/components/CoursesListCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PublicProfile = () => {
  const classes = useStyles();
  const { userId } = useParams();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    country: "",
  });
  useEffect(() => {
    actions
      .getUserProfileDetailsWithProfileId(userId)
      .then((response) => {
        setUserData(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  return (
    <Page className={classes.root} title="Public Profile">
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={4} md={6} xs={12}>
            <Grid container alignContent="center" spacing={1}>
              <Grid item xs={12}>
                <ProfileCard profileData={userData} />
              </Grid>
              <Grid item xs={12}>
                <BioCard profileData={userData} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Grid container alignItems="stretch">
              <Grid item xs={12}>
                <CoursesListCard creatorId={userId} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PublicProfile;
