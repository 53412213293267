import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((
  theme // prettier-ignore
) => ({
  root: {
    height: "100%",
    borderRadius: 25,
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

const Welcome = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography color="textPrimary" variant="h1">
                Learning Platform
              </Typography>
              <Typography paragraph color="secondary" variant="caption">
                [demo]
              </Typography>
              <Typography variant="body2" color="textSecondary">
                This demo showcases the various features our learning platform.
                You are welcome to test and give feedback. Thanks
              </Typography>
            </Grid>
            <Grid item>
              {/* <Typography color="textSecondary" variant="h5">
                Create and Learn.
              </Typography> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

Welcome.propTypes = {
  className: PropTypes.string,
};

export default Welcome;
