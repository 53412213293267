import React from "react";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import { makeStyles } from "@material-ui/core";
import ContentCard from "./ContentCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  courseCard: {
    height: "100%",
  },
}));

const CourseDetail = () => {
  const classes = useStyles();
  const { slug } = useParams();
  const { initPage } = useParams();

  return (
    <Page className={classes.root} title="Content">
      <ContentCard slug={slug} initPage={initPage} />
    </Page>
  );
};

export default CourseDetail;
