import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const OptionsGrid = ({ className, options, answers, index }) => {
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <RadioGroup value={value} onChange={handleChange}>
      {options.map((option, i) => {
        var answerId;
        if (!answers[index]) {
          answerId = undefined;
        } else {
          answerId = answers[index].option;
        }

        return (
          <FormControlLabel
            key={i}
            value={option.text}
            checked={answerId === option.id}
            control={
              <Radio
                onClick={() => {
                  answers[index] = {
                    option: option.id,
                    isCorrect: option.is_correct,
                  };
                }}
              />
            }
            label={option.text}
          />
        );
      })}
    </RadioGroup>
  );
};

export default OptionsGrid;
