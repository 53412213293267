import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Card,
  Grid,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import MyCourseUsers from "./MyCourseUsers";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const MyCourseCard = ({ course }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [size, setSize] = useState(0);

  useEffect(() => {}, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Page className={classes.root} title="Users">
      <Container maxWidth={false}>
        <Grid>
          <Card>
            <CardContent>
              <Typography color="textPrimary" variant="h5">
                {course.name}
              </Typography>
              <Typography color="secondary" variant="caption">
                {size < 2 ? `${size} Enrollment` : `${size} Enrollments`}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                aria-label="edit"
                onClick={() => {
                  navigate(`/app/edit_course/${course.slug}`);
                }}
              >
                <EditIcon color="textSecondary" />
              </IconButton>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <MyCourseUsers setSize={setSize} course={course.id} />
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
};

export default MyCourseCard;
