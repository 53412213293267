import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { API_URL } from "src/constants";
import CollapseAlert from "src/components/Alert";
import { resizeFile } from "src/components/Functions";

const useStyles = makeStyles(() => ({
  root: { borderRadius: 25 },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [fileUpload, setFile] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [avatar, setAvatar] = useState(
    "/static/images/avatars/profile_image_placeholder.png"
  );

  useEffect(() => {
    const full_name =
      localStorage.getItem("first_name") +
      " " +
      localStorage.getItem("last_name");
    setName(full_name);
    setCountry(localStorage.getItem("country"));
    setAvatar(localStorage.getItem("avatar"));
  }, [fileUpload]);

  const uploadHandler = () => {
    const formData = new FormData();
    if (fileUpload === "") {
      setAlertMessage("No file selected!");
      setSeverity("warning");
      setError(true);
      return;
    }
    formData.append("image", fileUpload, fileUpload.name);
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "patch",
      url: `users/profiles/${localStorage.getItem("user_id")}/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        localStorage.setItem("avatar", response.data.image);
        setFile("");
      })
      .catch((error) => {
        console.log("Error occured: ", error);
      });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={avatar} />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {country}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs>
            <input
              type="file"
              onChange={async (event) => {
                try {
                  const file = event.target.files[0];
                  const image = await resizeFile(file);
                  if (image.size > 1024000) {
                    setAlertMessage(
                      "File too large. Should not be more than 1MB! Image will not be uploaded"
                    );
                    setSeverity("error");
                    setError(true);
                  } else {
                    setFile(image);
                  }
                } catch (err) {
                  console.log(err);
                }
              }}
            />
          </Grid>
          <CollapseAlert
            message={alertMessage}
            severity={severity}
            active={error}
            onClose={() => {
              setError(false);
            }}
          />
          <Grid item xs>
            <Button
              color="primary"
              fullWidth
              variant="text"
              // type="submit"
              onClick={uploadHandler}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
