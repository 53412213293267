import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Page from "src/components/Page";
import { Box, Container, Button, Grid, makeStyles } from "@material-ui/core";
import CollapseAlert from "src/components/Alert";
import * as actions from "src/actions/auth";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {},
});

const EmailVerifyView = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userId, token } = useParams();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  useEffect(() => {
    // check verification
    actions
      .verifyEmail(userId, token)
      .then((response) => {
        // update verification in userprofile
        actions.patchProfileVerified();
        // show alert for success
        setError(true);
        setSeverity("info");
        setMessage("Your email has been verified!");
      })
      .catch((error) => {
        // show alert for failure
        setError(true);
        setSeverity("error");
        setMessage("Cannot verify email. Please try again later.");
      });
  }, [token, userId]);

  return (
    <Page className={classes.root} title="Password Reset">
      <Container maxWidth="lg">
        <Box mt={3}>
          <Grid align="center">
            <CollapseAlert
              message={message}
              severity={severity}
              active={error}
              onClose={() => {
                setError(false);
                setDisable(false);
                setMessage("");
              }}
            />
            <Button
              color="primary"
              variant="contained"
              disabled={disable}
              onClick={() => {
                navigate("/");
              }}
            >
              Go to home
            </Button>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

EmailVerifyView.propTypes = {
  className: PropTypes.string,
};

export default EmailVerifyView;
