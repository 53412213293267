import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Grid,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import Logo from "src/components/Logo";
import * as actions from "src/actions/auth";
import { paystackScript } from "src/components/Functions";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  const runGetNotifications = () => {
    actions
      .getNotifications("False")
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    runGetNotifications();
    paystackScript();
    // const interval = setInterval(() => {
    //   runGetNotifications();
    // }, 10000);
    // return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [window.performance]);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to={localStorage.getItem("token") ? "/app/courses" : "/"}>
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />

        {localStorage.getItem("user") && (
          <Grid>
            <IconButton
              color="inherit"
              onClick={() => {
                actions.logout();
                navigate("/");
              }}
            >
              <InputIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                navigate(`/app/notifications`);
              }}
            >
              <Badge badgeContent={notifications.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Hidden lgUp>
              <IconButton color="inherit" onClick={onMobileNavOpen}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
