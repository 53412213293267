import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import Courses from "./Courses";
import MyScores from "./MyScores";
import Progress from "./Progress";
import Enrollments from "./Enrollments";
import Earnings from "./Earnings";
import MyEnrollments from "./MyEnrollments";
import MyCourses from "./MyCourses";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [enrollments, setEnrollments] = useState([]);
  const [earnings, setEarnings] = useState(0);
  const [meanProgress, setProgress] = useState(0);
  const [isTutor, setIsTutor] = useState(false);

  // const calculateEarnings = (enrollments) => {
  //   let totalEarning = 0.0;
  //   enrollments.forEach((enrollment) => {
  //     if (
  //       enrollment.course_creator.toString() === localStorage.getItem("user_id")
  //     ) {
  //       totalEarning = totalEarning + enrollment.course_price;
  //     }
  //   });
  //   return totalEarning;
  // };

  const calculateProgress = (enrollments) => {
    let meanProgress = 0.0;
    if (enrollments.length < 1) {
      return 0.0;
    }
    enrollments.forEach((enrollment) => {
      meanProgress =
        meanProgress + enrollment.max_progress / enrollment.progress_final;
    });
    return ((100 * meanProgress) / enrollments.length).toFixed(0);
  };

  useEffect(() => {
    actions
      .getMyProfileDetails()
      .then((response) => {
        setIsTutor(response.data.access === "1");
        setEarnings(response.data.wallet_balance);
      })
      .catch((error) => {
        console.log(error);
      });

    actions
      .getMyCourses()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    actions
      .getMyEnrollments()
      .then((response) => {
        setEnrollments(response.data);
        setProgress(calculateProgress(response.data));
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    // actions
    //   .getMyCourseEnrollments()
    //   .then((response) => {
    //     setEarnings(calculateEarnings(response.data));
    //   })
    //   .catch((error) => {
    //     console.log("Error: ", error);
    //   });
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        {localStorage.getItem("user") ? (
          <Grid>
            <Grid container spacing={1}>
              <Grid item lg={9} sm={9} xs={9}>
                <Grid container>
                  <Typography variant="h3">Welcome, </Typography>
                  &nbsp;
                  <Typography variant="h3" color="primary">
                    {localStorage.getItem("first_name")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg={3} sm={3} xs={3}>
                {isTutor && (
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        navigate("/app/create_course");
                      }}
                    >
                      Create Course
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {isTutor && (
                <Grid item lg={3} sm={6} xs={12}>
                  <Courses number={courses.length} />
                </Grid>
              )}
              <Grid item lg={3} sm={6} xs={12}>
                <Enrollments data={enrollments} />
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <Progress number={meanProgress} />
              </Grid>
              {isTutor && (
                <Grid item lg={3} sm={6} xs={12}>
                  <Earnings total={earnings} />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              {isTutor && (
                <Grid item lg={4} sm={6} xs={12}>
                  <MyCourses />
                </Grid>
              )}
              <Grid item lg={4} sm={6} xs={12}>
                <MyEnrollments />
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <MyScores />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography>You are not signed in.</Typography>
            <Link component={RouterLink} to={`/register/`} variant="h6">
              Sign in/Sign up
            </Link>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default Dashboard;
