import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import Welcome from "src/views/reports/DashboardView/Welcome";
import CollapseAlert from "src/components/Alert";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/app/courses");
    }
  });

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          direction="row"
          alignContent="center"
          justify="space-evenly"
          style={{ minHeight: "90vh" }}
        >
          <Grid item sm={12} xs={12}>
            <Welcome />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Grid item xs={12} align="center">
              <CollapseAlert
                active={error}
                severity="error"
                message="Incorrect Login Details!"
                onClose={() => {
                  setError(false);
                  setDisable(false);
                }}
              />
            </Grid>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  password: Yup.string()
                    .max(255)
                    .required("Password is required"),
                })}
                onSubmit={(values) => {
                  actions
                    .authLogin(values.email, values.password)
                    .then((response) => {
                      localStorage.setItem("token", response.data.token);
                      localStorage.setItem("user", values.email);
                      actions.postBreadcrumb(
                        "post",
                        "users/token/",
                        "logged in"
                      );
                      navigate("/app/courses");
                    })
                    .catch((error) => {
                      setError(true);
                      setDisable(true);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      placeholder="john@doe.com"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      placeholder="*"
                    />
                    <Box my={3}>
                      <Button
                        color="primary"
                        disabled={disable}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Log in
                      </Button>
                    </Box>
                    <Typography color="textSecondary" variant="body1">
                      Don&apos;t have an account?{" "}
                      <Link component={RouterLink} to="/register" variant="h6">
                        Sign up
                      </Link>
                    </Typography>
                    <Typography color="secondary" variant="body1">
                      <Link
                        component={RouterLink}
                        to="/forgot_password"
                        variant="h6"
                      >
                        Forgot password?
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default LoginView;
