import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  CardMedia,
} from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { API_URL } from "src/constants";
import CardActionArea from "@material-ui/core/CardActionArea";
import CourseSynopsisDialog from "./CourseSynopsis";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 25,
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  media: {
    height: "320",
  },
}));

const CourseCard = ({ className, course, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [enrollments, setEnrollments] = useState(0);
  const [openSynopsis, setOpenSynopsis] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
    };
    API_URL({
      method: "get",
      url: `courses/course_enrollments/?course=${course.id}`,
      data: JSON.stringify({}),
      headers: headers,
    })
      .then((response) => {
        setEnrollments(response.data.length);
      })
      .catch((error) => {
        console.log("Error occured: ", error);
      });
  }, [course.id]);

  const handleClickOpen = () => {
    setOpenSynopsis(true);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CourseSynopsisDialog
        isOpen={openSynopsis}
        setOpenSynopsis={setOpenSynopsis}
        course={course}
      />
      <CardActionArea
        onClick={() => {
          if (course.creator === localStorage.getItem("user")) {
            // navigate(`/app/courses/${course.slug}`);
            handleClickOpen();
          }
          actions
            .getMyCourseEnrollment(course.id)
            .then((response) => {
              navigate(`/app/courses/${course.slug}`);
            })
            .catch((error) => {
              handleClickOpen();
            });
        }}
      >
        <CardMedia component="img" height="320" image={course.image} />
        <CardContent>
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {course.name}
          </Typography>
          <Typography align="left" color="textPrimary" variant="body2">
            {course.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid container justify="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            <Typography color="textSecondary" display="inline" variant="body2">
              {course.creator_name}
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <AssignmentTurnedInIcon
              className={classes.statsIcon}
              color="secondary"
            />
            <Typography color="textSecondary" display="inline" variant="body2">
              {enrollments} {enrollments > 1 ? `Enrollments` : `Enrollment`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

CourseCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.object.isRequired,
};

export default CourseCard;
