import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    textTransform: "none",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const ActionItem = ({
  className,
  onClick,
  icon: Icon,
  title,
  size,
  ...rest
}) => {
  const classes = useStyles();
  const fontSize = size ? size : "large";

  return (
    <Button className={classes.button} onClick={onClick} size="small">
      {Icon && <Icon className={classes.icon} fontSize={fontSize} />}
      <span className={classes.title}>{title}</span>
    </Button>
  );
};

ActionItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default ActionItem;
