import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
} from "@material-ui/core";
import CollapseAlert from "src/components/Alert";
import * as actions from "src/actions/auth";

const useStyles = makeStyles({
  root: {},
});

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [values, setValues] = useState({
    oldPassword: "",
    password: "",
    confirm: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader subheader="Change password" title="Password" />
        <Divider />
        <CardContent>
          <CollapseAlert
            message={message}
            severity={severity}
            active={error}
            onClose={() => {
              setError(false);
              setDisable(false);
              setMessage("");
            }}
          />
          <TextField
            fullWidth
            label="Old Password"
            margin="normal"
            name="oldPassword"
            onChange={handleChange}
            type="password"
            value={values.oldPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="New Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm New password"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            disabled={disable}
            onClick={() => {
              if (values.password !== values.confirm) {
                setMessage("Passwords do not match!");
                setSeverity("error");
                setError(true);
                setDisable(true);
              } else {
                actions
                  .changePassword(values.oldPassword, values.password)
                  .then((response) => {
                    setMessage("Password updated!");
                    setSeverity("info");
                    setError(true);
                  })
                  .catch((error) => {
                    setMessage(
                      "Something went wrong, Make sure the old password is correct."
                    );
                    setSeverity("error");
                    setError(true);
                  });
              }
            }}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default Password;
