import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import * as actions from "src/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isTutor, setIsTutor] = useState(false);

  useEffect(() => {
    // get user profile
    actions
      .getMyProfileDetails()
      .then((response) => {
        setIsTutor(response.data.access === "1");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleButton = () => {
    if (isTutor) {
      return (
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={() => {
            navigate("/app/create_course");
          }}
        >
          Create Course
        </Button>
      );
    }

    if (localStorage.getItem("user")) {
      // user is signed in
      return <Grid></Grid>;
    }

    return (
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={() => {
          navigate("/register");
        }}
      >
        Signup/Login
      </Button>
    );
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container>
        <Grid item lg={9} sm={9} xs={9}>
          <Typography variant="h3" color="textSecondary" gutterBottom>
            Courses List
          </Typography>
        </Grid>
        <Grid item lg={3} sm={3} xs={3}>
          <Box display="flex" justifyContent="flex-end">
            {handleButton()}
          </Box>
        </Grid>
      </Grid>
      {/* TODO: implement courses search. Filter in frontend */}
      {/* <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search course"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box> */}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
