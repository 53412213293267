import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import * as actions from "src/actions/auth";
import RichTextEditor from "react-rte";
import { DeleteForever } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: 25,
  },
  image: {
    height: 48,
    width: 48,
  },
});

const ImagesList = ({ className, ...rest }) => {
  const { setOpen, setHtmlString, setUpdated, updated } = rest;
  const classes = useStyles();
  const [items, setItems] = useState([]);

  useEffect(() => {
    actions
      .getImages(updated)
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, [updated]);

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <Divider />
      {items.length > 0 ? (
        <List>
          {items.map((item, i) => (
            <ListItem
              key={i}
              button
              onClick={() => {
                setHtmlString((content) => {
                  return RichTextEditor.createValueFromString(
                    `${content.toString(
                      "html"
                    )}<p style="text-align: center"><img src="${
                      item.link
                    }" width="320" class="center" /></p>`,
                    "html"
                  );
                });
                setOpen(false);
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt={`${item.about} image`}
                  src={item.link}
                  variant="rounded"
                />
              </ListItemAvatar>
              <ListItemText secondary={item.about} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    actions
                      .deleteImage(item.id)
                      .then((response) => {
                        setUpdated((update) => {
                          return update + 1;
                        });
                        // setOpen(False)
                      })
                      .catch((error) => {});
                  }}
                >
                  <DeleteForever color="textSecondary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="caption">No images!</Typography>
      )}
      <Divider />
    </Box>
  );
};

ImagesList.propTypes = {
  className: PropTypes.string,
};

export default ImagesList;
