import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import * as actions from "src/actions/auth";

const useStyles = makeStyles(() => ({
  root: { borderRadius: 25 },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const CoursesListCard = ({ className, creatorId, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [courseListData, setCourseListData] = useState(undefined);

  useEffect(() => {
    actions
      .getUserCourses(creatorId)
      .then((response) => {
        setCourseListData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [creatorId]);

  if (courseListData === undefined) {
    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Published Courses" />
        <Divider />
        <List>
          <ListItem>
            <Typography variant="caption" color="secondary">
              Could not retrieve user's courses.
            </Typography>
          </ListItem>
        </List>
      </Card>
    );
  }

  const CoursesList = () => {
    return (
      <List
        className={classes.root}
        component="nav"
        aria-label="secondary mailbox folders"
        dense
      >
        {courseListData.slice(0, courseListData.length).map(
          (course, index) =>
            course.published === "1" && (
              <Grid key={index}>
                <ListItem
                  button
                  onClick={() => {
                    navigate(`/app/courses/${course.slug}`);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="Course" src={course.image} variant="rounded" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={course.name}
                    secondary={course.description}
                    color="secondary"
                  />
                </ListItem>
              </Grid>
            )
        )}
      </List>
    );
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Published Courses" />
      <Divider />
      <Box display="flex" flexDirection="column">
        {courseListData.length > 0 ? (
          <CoursesList />
        ) : (
          <List>
            <ListItem>
              <Typography variant="h5" color="textSecondary">
                The user has no published courses
              </Typography>
            </ListItem>
          </List>
        )}
      </Box>
    </Card>
  );
};

CoursesListCard.propTypes = {
  className: PropTypes.string,
};

export default CoursesListCard;
